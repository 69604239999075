import { getPersonalDataMessages } from '@/data/use-cases/messages/page-messages/messages.personalData'
import { PagesMessages, PossiblePageMessageOptions } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { GetPageMessages } from '@/domain/use-cases/messages/get-page-messages'
import { TFunction } from 'i18next'
import { getAddressMessages } from './page-messages/messages.address'
import { getAttachmentsMessages } from './page-messages/messages.attachments'
import { getAuthorizationCodeMessages } from './page-messages/messages.authorization-code'
import { getCompletedMessages } from './page-messages/messages.completed'
import { getDigitalSignatureMessages } from './page-messages/messages.digitalSignature'
import { getDigitalStoreMessages } from './page-messages/messages.digitalStore'
import { getDigitalStoreBrasilMessages } from './page-messages/messages.digitalStoreBrasil'
import { getDigitalStoreStatusAccountLinkStepMessages } from './page-messages/messages.digitalStoreStatusAccountLinkStep'
import { getDigitalStoreStatusAccountResetStepMessages } from './page-messages/messages.digitalStoreStatusAccountResetStep'
import { getDigitalStoreStatusAccountStepMessages } from './page-messages/messages.digitalStoreStatusAccountStep'
import { getDigitalStoreStatusDefaultContentMessages } from './page-messages/messages.digitalStoreStatusDefaultContent'
import { getDigitalStoreStatusEmailStepMessages } from './page-messages/messages.digitalStoreStatusEmailStep'
import { getDigitalStoreStatusIntroductionStepMessages } from './page-messages/messages.digitalStoreStatusIntroductionStep'
import { getDigitalStoreStatusNotFoundMessages } from './page-messages/messages.digitalStoreStatusNotFound'
import { getDigitalStoreStatusPaymentMessages } from './page-messages/messages.digitalStoreStatusPayment'
import { getDigitalStoreStatusPendingAgreementsMessages } from './page-messages/messages.digitalStoreStatusPendingAgreements'
import { getDigitalStoreStatusPendingDocumentValidationMessages } from './page-messages/messages.digitalStoreStatusPendingDocumentValidation'
import { getDocumentationMessages } from './page-messages/messages.documentation'
import { getIndicationMessages } from './page-messages/messages.indication'
import { getMaintenanceAddress } from './page-messages/messages.maintenance-address'
import { getMaintenanceAddressApprovedMessages } from './page-messages/messages.maintenance-address-approved'
import { getMaintenanceAddressRejectedMessages } from './page-messages/messages.maintenance-address-rejected'
import { getMaintenancePersonalData } from './page-messages/messages.maintenance-personal-data'
import { getMaintenancePhoneEmailApprovedMessages } from './page-messages/messages.maintenance-phone-email-approved'
import { getMaintenancePhoneEmailRejectedMessages } from './page-messages/messages.maintenance-phone-email-rejected'
import { getMaintenanceRegister } from './page-messages/messages.maintenance-register'
import { getMaintenanceSecurityValidation } from './page-messages/messages.maintenance-security-validation'
import { getMaintenanceValidationCode } from './page-messages/messages.maintenance-validation-code'
import { getMyProfile } from './page-messages/messages.myProfile'
import { getPaymentAccountStatusMessages } from './page-messages/messages.paymentAccountStatus'
import { getPaymentAccountStatusSuccessMessages } from './page-messages/messages.paymentAccountStatusSuccess'
import { getPendingSignatureMessages } from './page-messages/messages.pendingSignature'
import { getPreRegisterMessages } from './page-messages/messages.preRegister'
import { getPreRegisterConfirmationMessages } from './page-messages/messages.preRegisterConfirmation'

const messagesCallbackByPage: {
  [value in Page]: (translate: TFunction, options?: PossiblePageMessageOptions) => PagesMessages[value]
} = {
  [Page.Documentation]: getDocumentationMessages,
  [Page.DigitalSignaturePage]: getDigitalSignatureMessages,
  [Page.DigitalStore]: getDigitalStoreMessages,
  [Page.PersonalData]: getPersonalDataMessages,
  [Page.PreRegister]: getPreRegisterMessages,
  [Page.Attachments]: getAttachmentsMessages,
  [Page.DigitalStoreBrasil]: getDigitalStoreBrasilMessages,
  [Page.PaymentAccountStatus]: getPaymentAccountStatusMessages,
  [Page.PaymentAccountStatusSuccess]: getPaymentAccountStatusSuccessMessages,
  [Page.Address]: getAddressMessages,
  [Page.AuthorizationCode]: getAuthorizationCodeMessages,
  [Page.Completed]: getCompletedMessages,
  [Page.PreRegisterConfirmation]: getPreRegisterConfirmationMessages,
  [Page.PendingSignature]: getPendingSignatureMessages,
  [Page.Indication]: getIndicationMessages,
  [Page.DigitalStoreStatus]: () => undefined,
  [Page.DigitalStoreStatusNotFound]: getDigitalStoreStatusNotFoundMessages,
  [Page.DigitalStoreStatusDefaultContent]: getDigitalStoreStatusDefaultContentMessages,
  [Page.DigitalStoreStatusPayment]: getDigitalStoreStatusPaymentMessages,
  [Page.DigitalStoreStatusPendingAgreements]: getDigitalStoreStatusPendingAgreementsMessages,
  [Page.DigitalStoreStatusIntroductionStep]: getDigitalStoreStatusIntroductionStepMessages,
  [Page.DigitalStoreStatusDocumentValidation]: getDigitalStoreStatusPendingDocumentValidationMessages,
  [Page.DigitalStoreStatusEmailStep]: getDigitalStoreStatusEmailStepMessages,
  [Page.DigitalStoreStatusAccountStep]: getDigitalStoreStatusAccountStepMessages,
  [Page.DigitalStoreStatusAccountResetStep]: getDigitalStoreStatusAccountResetStepMessages,
  [Page.DigitalStoreStatusAccountLinkStep]: getDigitalStoreStatusAccountLinkStepMessages,
  [Page.MaintenanceRegister]: getMaintenanceRegister,
  [Page.MaintenancePersonalData]: getMaintenancePersonalData,
  [Page.MaintenanceAddress]: getMaintenanceAddress,
  [Page.MaintenanceSecurityValidation]: getMaintenanceSecurityValidation,
  [Page.MaintenanceValidationCode]: getMaintenanceValidationCode,
  [Page.MaintenanceValidationCodePhoneEmail]: getMaintenanceValidationCode,
  [Page.MaintenanceAddressApproved]: getMaintenanceAddressApprovedMessages,
  [Page.MaintenanceAddressRejected]: getMaintenanceAddressRejectedMessages,
  [Page.MaintenancePhoneEmailApproved]: getMaintenancePhoneEmailApprovedMessages,
  [Page.MaintenancePhoneEmailRejected]: getMaintenancePhoneEmailRejectedMessages,
  [Page.MyProfile]: getMyProfile,
}

export const getPageMessages: GetPageMessages = (
  page: Page,
  translate: TFunction,
  options?: PossiblePageMessageOptions
) => {
  const getMessages = messagesCallbackByPage[page]
  return getMessages(translate, options)
}
