import styled from 'styled-components'
// import {
//   Input as DSInput,
// } from '@naturacosmeticos/natds-web'
import {
  GayaButton, Input as DSInput
} from '@naturacosmeticos/natds-react'


export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  >div {
    align-self: auto;
  }
  >div:first-of-type {
    flex: 1;
  }
`

export const Input = styled(DSInput)`
  flex: 1;
  border-radius: 16px!important;
  width: calc(100% - 116px);
  border: 
`
  
  
export const Button = styled(GayaButton)`
  min-width: 100px;
  margin-left: 16px;

`
