import React from 'react'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { dataLayerPush } from '@/analytics/helpers/dataLayerPush'
import { useBannerStyles } from './banner.styles'
import MGMBannerStart from './images/mgm-banner-start.svg'
import MGMLinkIcon from './images/mgm-link-icon.svg'
import { MgmModal } from './mgm-modal'
import { ConfirmSnackbar } from './confirm-snackbar'
import { useBanner } from './banner-context'

export const MGMBanner: React.FC = () => {
  const {
    button,
    imageArea,
    imageFrame,
    imageStyle,
    textWrapper,
    textTitle,
    textSubtitle,
    imageLink,
  } = useBannerStyles()
  const {
    mgmBanner: { startAlt, title, subtitle, linkAlt },
  } = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const { openModal, image } = useBanner()

  const handleOpenModal = () => {
    dataLayerPush('click_link_indication') // only on Minegocio (Hispana)
    openModal()
  }
  return (
    <>
      <MgmModal />
      <ConfirmSnackbar />

      <button type="button" className={button} onClick={handleOpenModal}>
        <div className={imageArea}>
          <img className={imageFrame} src={MGMBannerStart} alt="" />
          <img className={imageStyle} src={image} alt={startAlt} />
        </div>
        <p className={textWrapper}>
          <span className={textTitle}>{title}</span>
          <span className={textSubtitle}>{subtitle}</span>
        </p>
        <div className={imageLink}>
          <img src={MGMLinkIcon} alt={linkAlt} />
        </div>
      </button>
    </>
  )
}
