import React from 'react'
import { Card } from '@naturacosmeticos/natds-web'
import { Typography, Icon, buildTheme, Divider } from '@naturacosmeticos/natds-react'
import { themeNatura } from '@/main/config/theme'
import { useCustomCardStyles } from './custom-card.styles'
import { StyledTypography } from './custom-card.styled'

type CustomCardProps = {
  title: string
  className?: string
  children: React.ReactNode,
  editButtonName?: string,
  editButtonLink?: string,
  disableClick?: boolean,
  onClickCapture?: () => void
}

export const CustomCard = ({
  title,
  className,
  children,
  editButtonName,
  editButtonLink,
  disableClick = false,
  onClickCapture = () => {}
}: CustomCardProps) => {
  const classComponent = useCustomCardStyles()
  const theme = buildTheme(themeNatura, 'light')

  const handleClick = () =>  {
    onClickCapture()
    if (!disableClick) {
      window.location.assign(editButtonLink)
    }
  }

  return (
    <Card className={`${classComponent.container} ${className}`}>
      <div className={classComponent.titleContainer}>
        <StyledTypography variant="subtitle1">
          <span style={{color: theme.color.primary}}>
            {title}

          </span>
        </StyledTypography>
        {
          (editButtonName && editButtonLink)
          && (
          <div
            color="textPrimary"
            className={classComponent.editButton}
            onClickCapture={handleClick}
          >
            <Typography variant="body1">
              <span style={{color: theme.color.primary}}>
                {editButtonName}
              </span>
            </Typography>
            <Icon
              color="primary"
              name="outlined-action-edit"
              className={classComponent.editIcon}
            />
          </div>
          )
        }
      </div>

      <Divider />

      {children}
    </Card>
  )
}
