import { BrandThemes } from '@naturacosmeticos/natds-themes'

type Themes = {
    aesop: BrandThemes
    avon: BrandThemes
    avon_v2: BrandThemes
    biome: BrandThemes
    natdsTest: BrandThemes
    natura: BrandThemes
    theBodyShop: BrandThemes
    consultoriaDeBeleza: BrandThemes
    natura_v2: BrandThemes
    natura_v3: BrandThemes
    casaEestilo: BrandThemes
    consultoriaDeBeleza_v2: BrandThemes
}

export declare type ThemeMode = keyof BrandThemes
export declare type Brand = keyof Omit<Themes, 'natdsTest'>

export const theme: ThemeMode = 'light'
export const themeNatura: Brand = 'consultoriaDeBeleza_v2'


