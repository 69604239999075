import { MaintenanceRegisterMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenanceRegister(translate: TFunction): MaintenanceRegisterMessages {
  const t = (key: string) => translate(`maintenancePage.${key}`)

  return {
    title: t('title'),
    successMessage: t('successMessage'),
    unexpectedErrorMessage: t('unexpectedErrorMessage'),
    duplicatedEmailErrorMessage: t('duplicatedEmailErrorMessage'),
    emailAlreadyInUseErrorMessage: t('emailAlreadyInUseErrorMessage'),
    dialogButtonName: t('dialogButtonName'),
    cancelDialogButtonName: t('cancelDialogButtonName'),
    confirmationPhoneToRemoveMessage: t('confirmationPhoneToRemoveMessage'),
    nextButtonLabel: t('nextButtonLabel'),
    backButtonLabel: t('backButtonLabel'),
    requiredErrorMessage: translate('requiredErrorMessage'),
    firstDigitErrorMessage: t('firstDigitErrorMessage'),
    telephoneInvalid: t('telephoneInvalid'),
    invalidType: t('invalidType'),
    lengthErrorMessage: t('lengthErrorMessage'),
    email: {
      label: t('email.label'),
      placeholder: t('email.placeholder'),
      errorMessage: t('email.errorMessage'),
    },
    phoneTypes: {
      label: t('phoneTypes.label'),
      placeholder: t('phoneTypes.placeholder'),
      labels: {
        RESIDENTIAL: t('phoneTypes.labels.RESIDENTIAL'),
        MOBILE: t('phoneTypes.labels.MOBILE'),
        WHATSAPP: t('phoneTypes.labels.WHATSAPP'),
        MESSAGE: t('phoneTypes.labels.MESSAGE'),
      },
    },
    phoneNumber: {
      label: t('phoneNumber.label'),
      secondaryPhoneLabel: t('phoneNumber.secondaryPhoneLabel'),
      placeholder: t('phoneNumber.placeholder'),
      errorMessage: t('phoneNumber.errorMessage'),
      addPhoneNumber: t('phoneNumber.addPhoneNumber'),
      removePhoneNumber: t('phoneNumber.removePhoneNumber'),
    },
  }
}
