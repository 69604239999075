import React from 'react'
import { PendingMessages } from '@/main/pages/confirmation/pending/pending-wrapper'
import {
 DescriptionTexts, Content, Anchor,
} from '@/main/pages/confirmation/status-card.styles'
import { Description, HelpDescription } from '@/main/pages/confirmation/pending/pending.styles'
import {
  colorFromStatus,
  StatusCardContainer,
} from '@/main/components/status-card-container'
import { Icon } from '@naturacosmeticos/natds-react'
import { getEloToggleByCountry } from '@/utils/getEloToggle'
import { getObjectInStorage } from '@/main/hooks/useStorage'
import { Divider } from './pending.styles'

type PendingProps = {
  messages: PendingMessages,
  descriptionTranslationKey: string
}
const withoutLinkCountries = ['CO']

export const Pending: React.FC<PendingProps> = ({ messages, descriptionTranslationKey }) => {
  const currentLocale = getObjectInStorage('currentLocale') as any
  const country = withoutLinkCountries.includes(currentLocale?.region)
  
  return (
    <StatusCardContainer status={colorFromStatus.PRIMARY}>
      <Content>
        <Icon name="outlined-action-clock" testID='clock-image' size='hugeX' />
        <DescriptionTexts className="bold" variant="h5" color="textSecondary">
          {messages.title}
        </DescriptionTexts>
        <DescriptionTexts variant="subtitle2">
          {messages.subtitle}
        </DescriptionTexts>
        <Description variant="body1" color="textSecondary" className="description">
          {messages[descriptionTranslationKey].description}
        </Description>
        <DescriptionTexts className="bold" variant="subtitle1" color="textSecondary">
          {messages.helpTitle}
        </DescriptionTexts>
        <Divider>
          <HelpDescription variant="body1" color="textSecondary" text={messages[descriptionTranslationKey].helpDescription} />
        </Divider>
        {(getEloToggleByCountry() && country) ? <></> : (
          <Anchor
            color="default"
            component="a"
            href={messages.helpHref}
            underline="none"
            variant="body2"
            className="anchor_link"
          >
            {messages.helpAnchorText}
          </Anchor>
        )}
      </Content>
    </StatusCardContainer>
  )
}
