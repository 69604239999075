import styled from 'styled-components'
import { Checkbox as NatDsCheckbox } from '@naturacosmeticos/natds-web'

export const Checkbox = styled(NatDsCheckbox)`
  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${({ name, theme }) => {
      return name === 'isMexConvergence' ? '#AE0025' : theme.color?.primary
    }};
  }
  ${({ key }) => key === 'isMexConvergence' ? `
    .registers-fe-MuiCheckbox-root {
      color: #AE0025;
    }
  ` : ''}
`
