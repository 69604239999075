import { CountryId } from '@/domain/models/country'
import {
  CardContent,
  CardHeader,
  CardTitle,
  ControlledAddressNumberField,
  ControlledTextField,
  GeoCommercialConfig,
  GeoCommercialStructure,
  SelectOption,
} from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { Container } from '@/main/components/pages-structures'
import { FormContext, FormContextProps, NavigationContext, NavigationContextProps } from '@/main/contexts'
import { adaptDefaultValues } from '@/main/pages/address/adapters/input-adapter'
import {
  adaptFormValuesWithAnotherDeliveryAddress,
  adaptFormValuesWithDeliveryAddressCopied,
} from '@/main/pages/address/adapters/output-adapter'
import { LegacyAddressPageProps } from '@/main/pages/address/address-page-props'
import { useAddressPageStyles } from '@/main/pages/address/address-page.styles'
import { AddressFormValues } from '@/main/pages/address/models/address-form-values'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Spacing } from '@naturacosmeticos/natds-web/dist/Components/Spacing'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

const geoCommercialLevelsAdapter = {
  level1: 'department',
  level2: 'city',
  level3: 'neighborhood',
}

type ColombiaGeoCommercialLevelsIds = {
  department?: SelectOption
  city?: SelectOption
  neighborhood?: SelectOption
}

export const ColombiaAddressPage: React.FC<LegacyAddressPageProps> = ({ messages }) => {
  const { person, onSubmit } = useContext<FormContextProps>(FormContext)
  const { goToPreviousPage = () => {}, goToNextPage = () => {} } = useContext<NavigationContextProps>(
    NavigationContext
  )
  const defaultValues = adaptDefaultValues<ColombiaGeoCommercialLevelsIds>(person, geoCommercialLevelsAdapter)
  const formMethods = useForm<AddressFormValues & ColombiaGeoCommercialLevelsIds>({
    mode: 'onTouched',
    defaultValues,
  })
  const {
    control,
    formState: { isValid, isSubmitting },
    getValues,
  } = formMethods
  const [copyAddress, setCopyAddress] = useState<boolean>(true)

  useEffect(() => {
    if (!defaultValues.department) {
      return
    }
    setCopyAddress(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formRules = { required: messages.addressRequiredError }

  const addressPageClasses = useAddressPageStyles()

  const onNextButtonClick = () => {
    const formValues = getValues()
    const parsedValue = copyAddress
      ? adaptFormValuesWithDeliveryAddressCopied(formValues)
      : adaptFormValuesWithAnotherDeliveryAddress(formValues, geoCommercialLevelsAdapter)
    onSubmit(parsedValue, goToNextPage)
  }

  const geoCommercialConfig: GeoCommercialConfig = {
    level1: {
      id: 'department',
      label: messages.geographicalLevels.level1,
      placeholder: messages.geographicalLevels.placeholders.level1,
    },
    level2: {
      id: 'city',
      label: messages.geographicalLevels.level2,
      placeholder: messages.geographicalLevels.placeholders.level2,
    },
    level3: {
      id: 'neighborhood',
      label: messages.geographicalLevels.level3,
      placeholder: messages.geographicalLevels.placeholders.level3,
    },
    requiredErrorMessage: messages.addressRequiredError,
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={onNextButtonClick}
      disableNextButton={!isValid}
      isLoading={isSubmitting}
    >
      <CardHeader title={messages.title} subtitle={messages.subtitle} />
      <CardContent>
        <FormProvider {...formMethods}>
          <ControlledTextField
            id="street"
            label={messages.mainStreet.label}
            control={control}
            rules={formRules}
            placeholder={messages.placeholders.mainStreet}
            required
          />
          <div className={addressPageClasses.formRow}>
            <div className={addressPageClasses.numberInput}>
              <ControlledAddressNumberField
                id="number"
                label={messages.number.label}
                placeholder={messages.placeholders.number}
                rules={{
                  required: {
                    message: messages.addressRequiredError,
                    value: true,
                  },
                }}
                noNumberLabel={messages.checkBoxWithoutNumber}
              />
            </div>
            <div className={addressPageClasses.complementInput}>
              <ControlledTextField
                id="complement"
                label={messages.complementaryData.label}
                control={control}
                placeholder={messages.placeholders.complementaryData}
              />
            </div>
          </div>
          <ControlledTextField
            id="references"
            label={messages.references.label}
            control={control}
            rules={formRules}
            placeholder={messages.placeholders.references}
            required
          />
          <div className={addressPageClasses.copyAddressCheckbox}>
            <Checkbox
              color='primary'
              label={messages.confirmDeliveryAddressAsResidenceAddress}
              checked={copyAddress}
              onChange={(e) => setCopyAddress(e.target.checked)}
            />
          </div>
          {!copyAddress && (
            <>
              <Spacing marginY="semi">
                <Divider />
              </Spacing>
              <CardTitle title={messages.delivery.title} />
              <GeoCommercialStructure customConfig={geoCommercialConfig} countryId={CountryId.Colombia} />
              <ControlledTextField
                id="deliveryStreet"
                label={messages.mainStreet.label}
                control={control}
                rules={formRules}
                placeholder={messages.placeholders.mainStreet}
                required
              />
              <div className={addressPageClasses.formRow}>
                <div className={addressPageClasses.numberInput}>
                  <ControlledAddressNumberField
                    id="deliveryNumber"
                    label={messages.number.label}
                    placeholder={messages.placeholders.number}
                    rules={{
                      required: {
                        message: messages.addressRequiredError,
                        value: true,
                      },
                    }}
                    noNumberLabel={messages.checkBoxWithoutNumber}
                  />
                </div>
                <div className={addressPageClasses.complementInput}>
                  <ControlledTextField
                    id="deliveryComplement"
                    label={messages.complementaryData.label}
                    control={control}
                    placeholder={messages.placeholders.complementaryData}
                  />
                </div>
              </div>
              <ControlledTextField
                id="deliveryReferences"
                label={messages.references.label}
                control={control}
                rules={formRules}
                placeholder={messages.placeholders.references}
                required
              />
            </>
          )}
        </FormProvider>
      </CardContent>
    </Container>
  )
}
