import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { getEloToggleByCountry } from '@/utils/getEloToggle'

const translationPeruElo = require('../../../../public/locales/translation.NATURA_PER_ELO.json')
const translationColombiaElo = require('../../../../public/locales/translation.NATURA_COL_ELO.json')
const translationMexicoElo = require('../../../../public/locales/translation.NATURA_MEX_ELO.json')

const configs = require('./i18n.config')

const { defaultLanguage, resources } = configs

const resourcesElo = { ...resources,  NATURA_PER: { translation:  translationPeruElo }, NATURA_COL: { translation: translationColombiaElo }, NATURA_MEX: { translation: translationMexicoElo } }

export const initI18n = (language: string) => {
   i18n
    .use(initReactI18next)
    .init({
      resources: getEloToggleByCountry() ? resourcesElo : resources,
      lng: language,
      fallbackLng: defaultLanguage,
    })
   i18n.changeLanguage(language)

  return i18n
}
