import { useEffect, useRef, useState } from 'react'
import { Agreement } from '@/domain/use-cases/agreement'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreConfigs } from '@/domain/models/tenant/tenant-configs'
import { DigitalStoreTermsEnum } from '@/domain/models/digital-store/digital-store-terms-type'
import { DigitalStorePageApi } from '../api/make-digital-store-page-api'
import { DigitalStoreHookContext } from '../types'

export type DigitalStoreAgreementsResults = {
  lists: {
    ids: string[],
    complete: Agreement[],
  }
  isLoading: boolean
  isAccepted: boolean
  errorOnLoad: boolean
  userNeedsToAcceptTerms: boolean
  setIsTermsAccepted: (isAccepted: boolean) => void
  setUserNeedsToAcceptTerms: (userNeedsToAcceptTerms: boolean) => void
  unlink: () => Promise<void>
}

export type UseDigitalStoreAgreementsParams = {
  api: Pick<
    DigitalStorePageApi,
    'getAgreements' |
    'getPendingAgreements' |
    'unlinkAgreements'
  >
  context: DigitalStoreHookContext
}

export const useDigitalStoreAgreements = ({
  api,
  context,
}: UseDigitalStoreAgreementsParams): DigitalStoreAgreementsResults => {
  const {
    personId,
    tenantId,
    countryId,
    companyId,
  } = context

  const {
    agreement: {
      products,
    },
    acceptTermsStrategy,
  } = useTenantConfigs(tenantId, Page.DigitalStore) as DigitalStoreConfigs

  const setIsTermsAccepted = (isAccepted: boolean) => setDigitalStoreAgreements((state) => ({
    ...state,
    isAccepted,
  }))

  const setUserNeedsToAcceptTerms = (userNeedsToAcceptTerms: boolean) => setDigitalStoreAgreements((state) => ({
    ...state,
    userNeedsToAcceptTerms,
  }))

  const fetch = async () => {
    const onDone = (
      list: Agreement[],
      userNeedsToAcceptTerms: boolean,
      errorOnLoad = false,
    ) => {
      setDigitalStoreAgreements({
        ...digitalStoreAgreements,
        lists: {
          ids: list.map((item) => String(item.agreementId)),
          complete: list,
        },
        isLoading: false,
        errorOnLoad,
        userNeedsToAcceptTerms,
      })
    }

    try {
      let pendingList
      const isNaturaMexico = countryId === 'MEX' && companyId === 1
        const {
          agreements: list,
          userNeedsToAcceptTerms,
        } = await api.getAgreements({
          products,
          personId,
          countryId,
          companyId,
        })
        if (isNaturaMexico) {
          pendingList = await api.getPendingAgreements({
            products,
            personId,
            countryId,
            companyId,
          })
        }
      const listToSend = !isNaturaMexico ? list : pendingList.map((item) => ({
        ...item,
        ...list[0],
        agreementId: item.id,
      }))

      onDone(listToSend, listToSend.length === 0 ? false : userNeedsToAcceptTerms)
    } catch (error) {
      console.warn('Error fetchAgreements')

      onDone([], false, true)
    }
  }

  const unlink = async () => {
    const products = ['digital-store']

    try {
      await api.unlinkAgreements({
        personId,
        products,
      })
    } catch {
      console.warn('Error unlinkAgreement')
    }
  }

  const [
    digitalStoreAgreements,
    setDigitalStoreAgreements,
  ] = useState<DigitalStoreAgreementsResults>({
    lists: {
      ids: [],
      complete: [],
    },
    isLoading: true,
    isAccepted: false,
    errorOnLoad: false,
    setIsTermsAccepted,
    userNeedsToAcceptTerms: true,
    setUserNeedsToAcceptTerms,
    unlink,
  })

  const fetchRef = useRef(fetch)

  useEffect(() => {
    const userNeedsToAcceptTermsByStrategy = acceptTermsStrategy !== DigitalStoreTermsEnum.EMPTY
    const hasMandatoryProps = !!personId && !!countryId

    if (hasMandatoryProps && userNeedsToAcceptTermsByStrategy) {
      fetchRef.current()
    } else {
      setDigitalStoreAgreements((currentDigitalStoreAgreements) => ({
        ...currentDigitalStoreAgreements,
        isLoading: false,
        userNeedsToAcceptTerms: false,
      }))
    }
  }, [acceptTermsStrategy, countryId, personId])

  return digitalStoreAgreements
}
