import { AddressPageForms } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalSignaturePageConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'

import { AddressOrder, AddressPageType } from '../../address'
import { AgreementProduct } from '../../agreement/agreement-product'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { VendorId } from '../../vendor/vendor-id'

const personalData: PersonalDataConfigs = null

const preRegister: PreRegisterConfigs = null

const attachments: AttachmentsConfigs = null

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.EMPTY,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const documentation: DocumentationConfigs = null

const digitalSignaturePage: DigitalSignaturePageConfigs = null

const completed: CompletedConfigs = null

const authorizationCode: AuthorizationCodeConfigs = null

const maintenanceRegisterPage: MaintenanceRegisterConfigs = {
  phoneNumberAreaCode: '55',
  phoneNumberOptions: {
    mask: [
      '(',
      /[1-9]/,
      /[1-9]*/,
      ')',
      ' ',
      /[0-9]/,
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
    minLength: 11,
    maxLength: 11,
    mobileRegex: new RegExp(/^[1-9]{2}9[0-9]{8}$/),
  },
  phoneNumberSecondaryOptions: {
    mask: [
      '(',
      /[1-9]/,
      /[1-9]*/,
      ')',
      ' ',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
    ],
    minLength: 10,
    maxLength: 11,
  },
  shouldEditEmail: true,
  shouldEditPhone: true,
  showPhoneNumberSecondary: true,
  tenantTokenSecurityToggle: false, // Hoje Brasil usa uma toggle que vêm direto do Firebase, quando desligada, passar a usar esta
  primaryPhoneOptions: [1, 11],
  telephonesOptions: [
    {
      type: 1,
      mask: [
        '(',
        /[0-9]/,
        /[0-9]/,
        ')',
        ' ',
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
      validation: /^([0-9]{2})(9[0-9]{8})$/,
      minLength: 11,
      maxLength: 11,
    },
    {
      type: 2,
      mask: [
        '(',
        /[0-9]/,
        /[0-9]/,
        ')',
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
      validation: /^([0-9]{2})([0-9]{8})$/,
      minLength: 10,
      maxLength: 10,
    },
    {
      type: 5,
      mask: [
        '(',
        /[0-9]/,
        /[0-9]/,
        ')',
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]*/,
      ],
      configurationView: {
        phoneSize: 9,
        mask: [
          '(',
          /[0-9]/,
          /[0-9]/,
          ')',
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          ' ',
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
          /[0-9]/,
        ],
      },
      validation: /^([0-9]{2})([0-9]{8}|[0-9]{9})$/,
      minLength: 10,
      maxLength: 11,
    },
    {
      type: 11,
      mask: [
        '(',
        /[0-9]/,
        /[0-9]/,
        ')',
        ' ',
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
      validation: /^([0-9]{2})(9[0-9]{8}|[0-9]{8})$/,
      minLength: 10,
      maxLength: 11,
    },
    {
      type: 9,
      mask: [
        '(',
        /[0-9]/,
        /[0-9]/,
        ')',
        ' ',
        /[0-9]/,
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
      ],
      validation: /^([0-9]{2})(9[0-9]{8})$/,
      minLength: 11,
      maxLength: 11,
    },
  ],
}

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = {
  shouldEditEmail: true,
  shouldEditPhone: true,
}

const address: AddressConfigs = {
  pageType: AddressPageType.GeographicalLevels,
  shouldDisableTheFilledInput: false,
  zipCode: {
    rules: {
      maxLength: 9,
      pattern: new RegExp(/^[0-9]{5}-[0-9]{3}$/),
    },
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/],
    regex: /^(\d{5})(\d{3})+?$/,
    regexOption: '$1-$2',
    shouldDisplayLink: () => true,
  },
  neighborhood: {
    rules: {
      maxLength: 30,
    },
    validChars: new RegExp(/^[a-zA-Z0-9 çãõáéíóúÁÉÍÓÚ]+$/),
  },
  number: {
    validChars: new RegExp(/^[A-Za-z0-9]*\d+[A-Za-z0-9]*$/),
    hasNoNumberCheckbox: true,
    rules: {
      maxLength: 6,
    },
  },
  complement: {
    pattern: new RegExp(/^[A-Za-z0-9. ]*$/),
    rules: {
      maxLength: 50,
    },
    validChars: new RegExp(/^[a-zA-Z0-9 çãõáéíóúÁÉÍÓÚ]+$/),
  },
  street: {
    maxLength: 50,
    validChars: new RegExp(/^[a-zA-Z0-9 çãõáéíóúÁÉÍÓÚ]+$/),
  },
  reference: {
    maxLength: 100,
    validChars: new RegExp(/^[a-zA-Z0-9 çãõáéíóúÁÉÍÓÚ]+$/),
  },
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.NEIGHBORHOOD,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ],
  showAlertOnApprovedPage: true,
}

const profile: ProfileConfigs = {
  contact: {
    phoneMask: {
      maskRegex: /^(\d\d)(\d{4,5})(\d{4}).*/,
      mask: '$1 $2-$3',
    },
    editUrl: '/webfv/mfe-register/maintenanceRegisterPage/',
  },
  consultantData: {
    editUrl: '/webfv/mfe-register/maintenancePersonalDataPage/',
  },
  digitalSpace: {
    digitalSpaceBaseUrl: 'https://www.natura.com.br/consultoria/',
    editMySpacePath: '/webfv/cnd/my-space',
    mySpaceDashboard: '/webfv/digitalSpace',
    openDigitalSpaceUrl: '/webfv/digitalSpace',
    showDigitalSpace: true,
  },
  address: {
    getAddress: ({ street, number, complement, neighborhood, city, state }: AddressPageForms) => {
      const address = [street, number, complement, neighborhood, city].filter(Boolean).join(', ')
      const showState = state ? ` - ${state.toUpperCase()}` : ''
      return `${address} ${showState}`
    },
    editUrl: '/webfv/mfe-register/maintenanceAddressPage/',
  },
  hasEditProfile: true,
  hasSelfDeclaration: true,
  hasGender: true,
  hasSocialName: true,
  showPhoneNumberSecondary: true,
  showGroupSectorLeader: false,
}

export const naturaBraConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  digitalStore,
  address,
  digitalSignaturePage,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
