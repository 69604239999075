import { CountryId } from '../domain/models/country'

export const getProfileUrl = (countryId: CountryId) => {
  const links = {
    MEX: 'https://minegocio.natura.com.mx/nuevo-perfil',
    PER: 'https://minegocio.natura.com.pe/nuevo-perfil',
    CHL: 'https://minegocio.natura.cl/nuevo-perfil',
    COL: 'https://minegocio.natura.com.co/nuevo-perfil',
    ECU: 'https://minegocio.natura-avon.com.ec/nuevo-perfil',
  }

  const isProduction = process.env.ENVIRONMENT === 'prod'

  if (isProduction) {
    return links[countryId]
  }

  return `${process.env.MI_NEGOCIO_URL}/nuevo-perfil`
}
