import React, { ReactNode } from 'react'
import { FormControlLabel } from '@/main/components/form-control'
import { Checkbox as NatDsCheckbox } from './checkbox.styles'

export interface CheckboxProps {
  color: 'default' | 'primary' | 'secondary'
  label: ReactNode,
  value?: boolean,
  inputRef?: React.Ref<any>,
  name?: string,
  checked?: boolean,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: () => void,
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onChange,
  value,
  name,
  inputRef,
  onBlur,
  checked,
  color,
}) => (
  <FormControlLabel
    control={(
      <NatDsCheckbox
        data-testid="checkbox"
        name={name}
        color={color}
        checked={checked}
        onBlur={onBlur}
      />
    )}
    label={label}
    inputRef={inputRef}
    value={value}
    onChange={onChange}
  />
)
