import React from 'react'

type HeaderProps = {
    title: string
    description?: string
    hide?: boolean
    position: number
  }


const ballStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px', backgroundColor: '#F8B238', borderRadius: '50%', color: '#111111', minWidth: '32px', minHeight: '32px', marginRight: '10px'
}
// Header feito apenas para convergência México
export const Option = ({ title, description, position, hide }: HeaderProps) => (
  <>
    <div style={{ display: hide ? 'none': 'flex', flexDirection: 'column', marginBottom: '15px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ ...ballStyle,  }}>{position}</div>
        <h1 style={{ fontSize: '16px', fontWeight: 400, color: '#111111' }}>{title}</h1>
      </div>
      {description && <h2 style={{ fontSize: '12px', fontWeight: 400, color: '#737373', lineHeight: '1.5', marginLeft: '42px' }}>{description}</h2>}
    </div>
  </>
)
