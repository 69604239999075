import { Theme } from '@naturacosmeticos/natds-react'
import { Theme as ThemeUi } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useModalStyles = makeStyles<Theme & ThemeUi>((theme) => ({
  paper: {
    backgroundColor: theme.color.background,
    borderRadius: '20px',
    height: '246px',
    width: '496px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  titleWrapper: {
    color: theme.color.onSurface,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.color.onSurface,
  },
  closeModal: {
    cursor: 'pointer',
  },
  messagersWrapper: {
    display: 'flex',
    gap: '26px',
    width: '100%',
    justifyContent: 'center',
  },
  messager: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    fontSize: '11px',
    color: theme.color.onSurface,
  },
  inputWrapper: {
    width: '448px',
    height: '56px',
    padding: '15px 14px 15px 16px',
    borderRadius: '16px',
    border: `1px solid ${theme.color.neutralMedium}`,
    backgroundColor: theme.color.surface,
    color: theme.color.onSurface,
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
  },
  inputText: {
    flex: 1,
    fontSize: '14px',
    color: theme.color.neutralDark,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  inputButton: {
    border: 'none',
    backgroundColor: theme.color.primary,
    borderRadius: '13px',
    height: '26px',
    fontSize: '11px',
    padding: '5px 8px',
  },
}))
