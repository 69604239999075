import { DigitalStoreStatusCurrentStatusMessages } from '@/domain/models/messages/messages'
import { Button } from '@/main/components'
import { IconName } from '@naturacosmeticos/natds-icons'
import { Alert } from '@naturacosmeticos/natds-react'
import { Icon } from '@naturacosmeticos/natds-web'
import React from 'react'
import styled from 'styled-components'
import { StyledTextParagraph } from '../digital-store-status-base-card.styles'

type StatusComponentsProps = {
  statusMessages?: DigitalStoreStatusCurrentStatusMessages
  onClickFunction?: () => Promise<void>
}

const AlertStyled = styled(Alert)`
  div {
    display: flex;
    gap: 10px;
  }
`

const buttonStyle: React.CSSProperties = {
  display: 'flex',
  justifySelf: 'flex-start',
  borderRadius: '999px',
  textTransform: 'lowercase',
  marginBottom: '12px',
  paddingInline: '22px'
}


export const DefaultStatusComponent = ({ statusMessages, onClickFunction }: StatusComponentsProps) => {
  return (
    <>
      <StyledTextParagraph
        alignLeft
        content={statusMessages?.message}
      />
      <Button
        data-testid='button-open-iframe'
        color="primary"
        variant='outlined'
        style={buttonStyle}
        onClick={onClickFunction}
      >
        {statusMessages?.buttons.upload}
      </Button>
      <AlertStyled color='warning' showIcon={false}>
        <Icon size='tiny' name={'filled-alert-warning' as IconName} />
        <StyledTextParagraph alignLeft content={statusMessages?.warning.message} />
      </AlertStyled>
    </>
  )
}

export const SuccessStatusComponent = ({ statusMessages }: StatusComponentsProps) => {
  return (
    <AlertStyled color='success' showIcon={false}>
      <Icon size='tiny' name={'filled-alert-check' as IconName} />
      <StyledTextParagraph alignLeft content={statusMessages.success.message} />
    </AlertStyled>
  )
}

export const ErrorStatusComponent = ({ statusMessages, onClickFunction }: StatusComponentsProps) => {
  return (
    <>
      <AlertStyled color='error' showIcon={false}>
        <Icon size='tiny' name={'filled-alert-cancel' as IconName} />
        <StyledTextParagraph alignLeft content={statusMessages.error.message} />
      </AlertStyled>
      <Button
        style={{ ...buttonStyle, backgroundColor: '#FAC871', color: '#111111', margin: '8px 0 0' }}
        onClick={onClickFunction}
      >
        {statusMessages.buttons.retry}
      </Button>
    </>
  )
}
