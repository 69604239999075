export const dataLayerPush = (event: string, data?: object) => {
  try {
    if (!window.dataLayer) window.dataLayer = []
    window.dataLayer.push({
      event,
      ...data,
    })
  // eslint-disable-next-line no-empty
  } catch (_error) {}
}

declare global {
  interface Window {
    dataLayer: {
      event: string
      [key: string]: any
    }[]
  }
}
