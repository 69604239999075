import { HttpStatusCode, HttpPatchClient } from '@/data/protocols/http'
import {
  UpdateDigitalStore,
  UpdateDigitalStoreParams,
} from '@/domain/use-cases/digital-store/update-digital-store'
import { UnexpectedError } from '@/domain/errors'

import { CountryId } from '@/domain/models/country'
import { BadRequestError, ConflictError } from '@/data/protocols/errors'
import { CompanyId } from '@/domain/models/company/company-id'

export type UpdateDigitalStoreHttpRequestHeaders = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
  'x-api-key': string
}

export type UpdateDigitalStoreHttpRequestBody = {
  agreement: {
    id: string
    accepted: boolean
  }
  extraEntries: {}
}

export type UpdateDigitalStoreHttpRequestResponseBody = {
  saved: boolean
}

export class RemoteUpdateDigitalStore implements UpdateDigitalStore {
  constructor(
    private readonly url: string,
    private readonly httpPatchClient: HttpPatchClient<
      UpdateDigitalStoreHttpRequestHeaders,
      UpdateDigitalStoreHttpRequestBody,
      UpdateDigitalStoreHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  update = async (params: UpdateDigitalStoreParams): Promise<boolean> => {
    const headers: UpdateDigitalStoreHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      countryId: params.countryId,
      personId: params.personId,
      companyId: params.companyId,
    }

    const body: UpdateDigitalStoreHttpRequestBody = {
      agreement: {
        id: params.agreementId,
        accepted: params.accepted,
      },
      extraEntries: params.extraEntries,
    }

    const httpResponse = await this.httpPatchClient.patch({
      url: this.url.replace(/:digitalStoreName/i, params.digitalStoreName),
      headers,
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body.saved
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.conflict:
        throw new ConflictError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
