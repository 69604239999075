import { PERSON_SUBSTATUS_ID } from '@/domain/models/person'
import { BadRequestError } from '@/data/protocols/errors'
import { useState } from 'react'
import { DigitalStorePageApi } from '../api/make-digital-store-page-api'
import { DigitalStoreHookContext } from '../types'

type SaveParams = {
  accepted: boolean
  digitalStoreName: string
  agreementId: string
  extraEntries: {}
}

export type DigitalStoreSaveResults = {
  isSaved: boolean
  isSaving: boolean
  errorOnSave: boolean
  helperTextKey: string
  save: (params: SaveParams) => Promise<void>
  update: (params: SaveParams) => Promise<void>
  updateName: (digitalStoreName: string) => Promise<{digitalStoreName: string}>
}

export type UseDigitalStoreSaveParams = {
  convergence: boolean
  api: Pick<
    DigitalStorePageApi,
    'save' | 'updatePersonSubstatus' | 'update' | 'updateDigitalStoreName'
  >
  context: DigitalStoreHookContext
}

const delay = (size: number) => new Promise((resolve) => {
  setTimeout(() => resolve(null), size)
})

export const useDigitalStoreSave = ({
  convergence,
  api,
  context,
}: UseDigitalStoreSaveParams): DigitalStoreSaveResults => {
  const {
    role,
    personId,
    companyId,
    functionId,
    sourceSystem,
    businessModel,
    countryId,
  } = context

  const [digitalStoreSave, setDigitalStoreSave] = useState<DigitalStoreSaveResults>({
    isSaved: false,
    isSaving: false,
    errorOnSave: false,
    helperTextKey: '',
    save: async (params) => handleSaveOrUpdate('save', params),
    update: async (params) => handleSaveOrUpdate('update', params),
    updateName: async (digitalStoreName) => updateDigitalStoreName(digitalStoreName),
  })

  const onDone = (
    isSaved: boolean,
    errorOnSave: boolean,
    helperTextKey: string,
  ) => {
    setDigitalStoreSave({
      ...digitalStoreSave,
      isSaved,
      errorOnSave,
      helperTextKey,
      isSaving: false,
    })
  }

  const handleSaveOrUpdate = async (
    action: 'save' | 'update',
    params: SaveParams
  ) => {
    const { accepted, digitalStoreName, agreementId = '', extraEntries = {} } = params

    try {
      setDigitalStoreSave({
        ...digitalStoreSave,
        isSaving: true,
      })

      const isSaved = await api[action]({
        accepted,
        personId,
        countryId,
        companyId,
        agreementId,
        extraEntries,
        digitalStoreName,
      })

      if (convergence) {
        await delay(5000)
      } else {
        const substatusId = PERSON_SUBSTATUS_ID.FINAL_VALIDATION
        await api.updatePersonSubstatus({
          role,
          personId,
          countryId,
          companyId,
          functionId,
          substatusId,
          sourceSystem,
          businessModel,
        })
      }

      const hasError = !isSaved
      const helperTextKey = !isSaved ? 'errorSaving' : ''
      onDone(isSaved, hasError, helperTextKey)
    } catch (error) {
      console.warn('Error save')

      let helperTextKey: string
      switch (error.constructor) {
        case BadRequestError:
          helperTextKey = 'errorSaving'
          break
        default:
          helperTextKey = 'genericError'
      }

      const isSaved = false
      const hasError = true
      onDone(isSaved, hasError, helperTextKey)
    }
  }

  const updateDigitalStoreName = async (digitalStoreName: string) => {
    try {
      const response = await api.updateDigitalStoreName({
        digitalStoreName,
        personId,
      })

      onDone(true, false, '')
      return { digitalStoreName: response.digitalStoreName }

    } catch (error) {
      onDone(false, true, 'errorSaving')
      throw new Error()
    }
  }

  return digitalStoreSave
}
