import React from 'react'
import styled from 'styled-components'
import {
 TextParagraph, Spacing, Divider, CardHeader,
} from '@/main/components'

const Container = styled.div``

const StyledCardHeader = styled(CardHeader)`
  display: 'block';
  padding: 0;
`

type TitleProps = {
  className?: string
  children : JSX.Element | JSX.Element[] | string
}

const Title = styled(({ className, children }: TitleProps) => (
  <div className={className}>
    <div className="title-bar" />
    <StyledCardHeader
      title={children}
    />
  </div>
))`
  display: flex;
  margin-left: -24px;
  padding-top: 36px;
  padding-bottom: 14px;
  align-items: center;

  h2 {
    font-size: 2rem;
    margin-bottom: 0;
  }


  .title-bar {
    border-radius: 0 4px 4px 0;
    background: ${({ theme }) => theme.color.primary} 0 0 no-repeat padding-box;
    width: 0.4rem;
    height: 3rem;
    margin-right: 20px;
    display: inline-block;
  }
`

type AdvantageItem = {
  title: string
  content: string
}

type ListProps = {
  className?: string
  items: AdvantageItem[]
}

const List = styled(({ className, items }: ListProps) => (
  <ol className={className}>
    {items.map((item) => (
      <li>
        <span className="title">{item.title}</span>
        <TextParagraph content={item.content} />
      </li>
    ))}
  </ol>
))`
  list-style: none;
  padding-left: 40px;
  letter-spacing: 0.26px;
  color: ${({ theme }) => theme.color.mediumEmphasis};
  counter-reset: advantage-counter;

  li {
    margin: 0 0 0.5rem 0;
    position: relative;
    margin-bottom: 26px;
    counter-increment: advantage-counter;

    &:before {
      content: counter(advantage-counter);
      color: ${({ theme }) => theme.color.neutral0};
      font-size: 2rem;
      position: absolute;
      --size: 40px;
      left: calc(-1 * var(--size) - 10px);
      line-height: var(--size);
      width: var(--size);
      height: var(--size);
      top: 0;
      background: ${({ theme }) => theme.color.primary} 0 0 no-repeat padding-box;
      border-radius: 50%;
      text-align: center;
    }

    .title {
      line-height: 1.6rem;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.color.mediumEmphasis};
      display: flex;
      align-items: flex-end;
      padding: 0 0 0.5rem;
      font-weight: 500;
    }
  }
`

export type AdvantagesProps = {
  title: string
  items: AdvantageItem[]
  shouldHide?: boolean
}

export const Advantages = ({ title, items, shouldHide }: AdvantagesProps) => (
  <>
    {!shouldHide && (
      <Container>
        <Title>{title}</Title>
        <List items={items} />
        <Spacing marginTop="semi">
          <Divider />
        </Spacing>
      </Container>
    )}
  </>
)
