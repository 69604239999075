import { ControlledTextField } from '@/main/components'
import { getOnlyNumbers } from '@/main/pages/register-maintenance/commons/common-fuctions'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import React from 'react'
import { ControllerProps, useFormContext } from 'react-hook-form'

type PhoneOptions = {
  maxLength: number
  minLength: number
}

type PhoneNumberMessages = {
  label: string
  placeholder?: string
  requiredErrorMessage: string
  firstDigitErrorMessage: string
  lengthErrorMessage: string
  telephoneInvalid: string
}

type PhoneNumberProps = {
  messages: PhoneNumberMessages
  id: string
  name: string
  mask?: Mask
  options?: PhoneOptions
  mobileRegex?: RegExp | null
  showAsterisk?: boolean
  phoneType?: number
  disabled?: boolean
} & Pick<ControllerProps<'input'>, 'rules' | 'control'>

export const PhoneNumber: React.FC<PhoneNumberProps> = ({
  messages,
  id,
  mask,
  options,
  mobileRegex = null,
  showAsterisk,
  disabled,
  phoneType
}: PhoneNumberProps) => {
  const { control, errors } = useFormContext()
  const maxLength = options?.maxLength || Infinity
  const minLength = options?.minLength || 0

  const lengthValidation = (value: string): boolean => {
    const onlyDigits = getOnlyNumbers(value)
    return onlyDigits.length <= maxLength && onlyDigits.length >= minLength
  }

  const validatePhoneNumber = (value: string) => {
    const onlyDigits = getOnlyNumbers(value)

    if (phoneType && phoneType === 2 && !lengthValidation(value)) {
      return messages.telephoneInvalid
    }

    if (options && !lengthValidation(value)) {
       return messages.lengthErrorMessage
    }

    if (mobileRegex) {
      const checkPhoneRegex = mobileRegex.test(onlyDigits)

      if (!checkPhoneRegex) {
        return messages.firstDigitErrorMessage
      }
    }

    return true
  }

  return (
    <ControlledTextField
      id={id}
      label={messages.label}
      aria-label={messages.label}
      control={control}
      disabled={disabled}
      placeholder={messages.placeholder}
      rules={{
        required: messages.requiredErrorMessage,
        validate: {
          id: validatePhoneNumber,
          phoneNumber: validatePhoneNumber,
        },
      }}
      errorMessage={{
        firstDigit: messages.firstDigitErrorMessage,
        length: messages.lengthErrorMessage,
      }}
      errors={errors}
      mask={mask}
      required
      showAsterisk={showAsterisk}
    />
  )
}
