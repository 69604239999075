import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'
import { deviceSize } from '@/utils/device-sizes'
import styled from 'styled-components'
import { Typography } from '@naturacosmeticos/natds-react'

export const StyledTypography = styled(Typography)`
  h6 {
    margin: 0 0 12px 0;
  }
`

export const useFormFieldsStyles = makeStyles<Theme>((theme) => {
  const displayEqualOrWiderThenTablet = theme.breakpoints.up(deviceSize.tablet)

  return {
    formRow: {
      [displayEqualOrWiderThenTablet]: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-between',
      },
    },
    container: {
        [displayEqualOrWiderThenTablet]: {
          display: 'flex',
          width: '50%',
          heigth: '100%',
          justifyContent: 'center',
          alighItems: 'center',
          flexDirection: 'column',
        },
      },
      main: {
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        alighItems: 'center',
        background: '#e7e7e7',
      },

      buttonContainer: {
        display: 'flex',
        justifyContent: 'end',
      },
      textFields: {
        background: '#fff',
        padding: '10%',
        borderRadius: '2%',
      },
      button: {

      },
  }
})
