import {
  CenteredLoadingContainer,
  LoadingContainer,
} from '@/main/components/pages-structures/container.styles'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import React, { useEffect, useRef, useState } from 'react'

export const IframeForm = ({ iframePayload }: { iframePayload?: { [key: string]: string } }) => {
  const [pageLoading, setPageLoading] = useState(true)
  const formRef = useRef(null)

  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }, [])

  const handleLoad = () => {
    setPageLoading(false)
  }

  return (
    <form
      data-testid='iframe-form'
      ref={formRef}
      action={iframePayload.url}
      method='POST'
      target='iframeTarget'
    >
      {pageLoading && (
        <LoadingContainer
          data-testid="form-loading-container"
          style={{ right: 0, backgroundColor: '#ffffff' }}
        >
          <CenteredLoadingContainer>
            <ProgressIndicator />
          </CenteredLoadingContainer>
        </LoadingContainer>
      )}

      {Object.entries(iframePayload).map(([key, value]) => (
        <input
          key={key}
          type='hidden'
          name={key}
          value={value}
        />
      ))}

      <iframe
        allow='geolocation *; microphone *; camera *;'
        data-testid='iframe'
        title='iframe'
        name='iframeTarget'
        loading="lazy"
        onLoad={handleLoad}
        style={{ width: '100%', height: '1450px', border: 'none' }}
      />
    </form>
  )
}
