import React from 'react'
import { FieldErrors } from 'react-hook-form'
import {
  FormHelperText, InputLabel,
} from '@naturacosmeticos/natds-web'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import {
  Input,
  Button,
  InputContainer,
} from '@/main/components/text-field/text-field-with-button.styles'
import { getLabel } from '@/main/components/text-field/helper/get-label'
import { InputStateHelpTextProvider } from '@naturacosmeticos/natds-web/dist/Components/InputStateHelpTextProvider'
import {
  CustomChangeEvent,
  handleOnChange,
} from '@/main/components/text-field/helper/handle-onchange'
import { getFinalErrorMessage, StringOrObjectOfStrings } from './helper/error-message-getter'

export type ButtonTextFieldProps = {
  buttonLabel: React.ReactNode
  buttonOnClick: () => void
  buttonOnChange?: () => void
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  shouldButtonBeDisabled?: boolean
}

export type InputTextFieldProps = {
  disabled?: boolean,
  mask?: Mask
  onBlur?: () => void
  onChange?: (event: CustomChangeEvent) => void
  ref?: React.MutableRefObject<any>
  value?: string
}

export type TextFieldWithButtonProps = {
  id?: string;
  label?: React.ReactNode;
  placeholder?: string;
  required?: boolean;
  errorMessage?: StringOrObjectOfStrings;
  successMessage?: StringOrObjectOfStrings;
  validChars?: RegExp
  infoText?: React.ReactNode;
  errors?: FieldErrors;
  isMexConvergence?: boolean;
  showAsterisk?: boolean;
  optionalText?: string;
  invalid?: boolean
  name?: string
  isSuccess?: boolean
  buttonProps:ButtonTextFieldProps
  extraButton?: React.ReactNode
  inputProps: InputTextFieldProps
}

export const TextFieldWithButton: React.FC<TextFieldWithButtonProps> = ({
id,
label,
placeholder,
required,
errorMessage,
successMessage,
validChars,
infoText,
errors,
isMexConvergence,
showAsterisk = true,
optionalText,
invalid,
inputProps,
buttonProps,
extraButton,
isSuccess = false,
}) => {
  const isMobile = window.innerWidth < 720
  const finalErrorMessage = getFinalErrorMessage(errors, errorMessage, id)
  const labelFormatted = getLabel({
    labelText: label, showAsterisk, required, optionalText,
  })
  const getState = () => {
    if (invalid) return 'error'
    if (isSuccess) return 'success'
    return null
  }

  return (
    <>
      <InputLabel state={getState()} htmlFor={id}>
        {labelFormatted}
      </InputLabel>
      <InputContainer style={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
        <div style={{ width: '100%', marginRight: isMobile ? '0' : '16px' }}>
          <Input
            ref={inputProps.ref}
            onBlur={inputProps.onBlur}
            value={inputProps.value}
            id={id}
            data-testid={id}
            placeholder={placeholder}
            disabled={inputProps.disabled}
            onChange={(event: CustomChangeEvent) => handleOnChange({
              event,
              onChange: inputProps.onChange,
              validChars,
            })}
          />
          <InputStateHelpTextProvider state={getState()} />
          <FormHelperText style={{ position: isMexConvergence ? 'absolute' : 'unset'}} state={getState()}>
            {/* @ts-ignore */}
            {invalid ? invalid.message || finalErrorMessage : undefined}
            {isSuccess ? successMessage : undefined}
          </FormHelperText>
          <FormHelperText>{infoText}</FormHelperText>
        </div>

        <div style={{ display: 'flex', marginTop: isMobile ? '36px' : '0' }}>
          <Button
            variant='outlined'
            disabled={buttonProps.shouldButtonBeDisabled}
            onClick={buttonProps.buttonOnClick}
          >
            {buttonProps.buttonLabel}
          </Button>
          {extraButton}
        </div>
      </InputContainer>
    </>
  )
}
