/* eslint-disable react-hooks/exhaustive-deps */
import { CountryId } from '@/domain/models/country'
import { MaintenancePersonalDataMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { BUSINESS_MODELS, FUNCTION_IDS, ROLES } from '@/domain/models/person'
import { redirectToProfile } from '@/domain/models/tenant/tenant-configs/helpers/redirect'
import { IconPopover } from '@/main/components'
import { IdentityInfoContext } from '@/main/contexts'
import { genderCodes } from '@/main/factories/presenters/gender-codes'
import { getGenderNames } from '@/main/factories/presenters/gender-labels'
import { racialSelfDeclarationOptions } from '@/main/factories/presenters/racial-self-declaration-codes'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { GenderField } from '@/main/pages/register-maintenance-personal-data/fields/gender-field'
import { RegisterMaitenancePageApi } from '@/main/pages/register-maintenance/api/make-register-maintenance-page-api'
import { Typography } from '@naturacosmeticos/natds-web'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { dataLayerPush } from '@/analytics/helpers/dataLayerPush'
import { personalDataFormPushes } from '@/analytics/formPushes'
import { Container } from '../register-maintenance-address/components/pages-structures'
import { DialogWithLoader } from '../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { MaintenanceDialog } from '../register-maintenance/fields/maintenance-dialog'
import { NicknameField } from './fields/nickname-field'
import { RacialSelfDeclarationField } from './fields/racial-self-declaration-field'
import { useMaintenancePersonalDataPage } from './maintenance-personal-data-page.styles'

export type MaintenancePersonalDataPageProps = {
  api: RegisterMaitenancePageApi
}

type DialogState = {
  message?: string
  isOpen: boolean
  confirmationButtonCallback?: () => void
}

export const MaintenancePersonalDataPage: React.FC<MaintenancePersonalDataPageProps> = ({ api }) => {
  const { personId, countryId, companyId, isCN, sourceSystem } = useContext(IdentityInfoContext)

  const componentClasses = useMaintenancePersonalDataPage()

  const {
    title,
    labels: {
      nickname,
      placeholderNickname,
      infoTextNickname,
      gender,
      saveButton,
      previousButton,
      racialSelfDeclaration,
      placeholderRacialSelfDeclaration,
      racialSelfDeclarationTooltip,
    },
    dialog: { buttonName, unexpectedErrorMessage, successMessage },
    requiredMessage,
  } = usePageMessages(Page.MaintenancePersonalData).messages as MaintenancePersonalDataMessages

  const nicknameMessages = {
    label: nickname,
    placeholder: placeholderNickname,
    infoText: infoTextNickname,
    requiredMessage,
  }
  const genderMessages = {
    label: gender,
    placeholder: gender,
    requiredMessage,
  }

  const racialSelfDeclarationMessages = {
    label: racialSelfDeclaration,
    placeholder: placeholderRacialSelfDeclaration,
    requiredMessage,
  }

  const actionMessages = {
    saveButton,
    previousButton,
    dialogButtonName: buttonName,
    unexpectedErrorDialogMessage: unexpectedErrorMessage,
    successDialogMessage: successMessage,
  }

  const getGenderOptions = (countryId: CountryId) =>
    Object.keys(genderCodes).map((key) => ({
      value: genderCodes[key],
      label: getGenderNames(countryId, key),
    }))

  const getRacialSelfDeclarationOptionById = (value: number) =>
    racialSelfDeclarationOptions.find((option) => option.value === value)

  const getGenderOptionById = (genderId) =>
    getGenderOptions(countryId).find(({ value }) => value === genderId)

  const genderOptions = useMemo(() => getGenderOptions(countryId), [countryId])
  const formMethods = useForm({
    mode: 'onTouched',
  })
  const [isLoading, setIsLoading] = useState(true)
  const [modalStatus, setModalStatus] = useState<DialogState>({ message: '', isOpen: false })
  const [openSubmitLoading, setOpenSubmitLoading] = useState(false)
  const [birthday, setBirthday] = useState('')
  const [name, setName] = useState('')

  const history = useHistory()

  const goBackPreviousPage = () => {
    dataLayerPush('form_canceled')
    if (isCN) {
      redirectToProfile(personId, sourceSystem)
    } else {
      window.location.reload()
      history.goBack()
    }
  }

  const onClose = () => {
    setModalStatus({ isOpen: false })
    goBackPreviousPage()
  }

  const onCloseWithoutPreviousPage = () => {
    setModalStatus({ isOpen: false })
  }

  const removeExtraSpace = (text) => (text ? text.replace(/\s+/g, ' ').trim() : '')

  const { getValues, reset, formState, watch } = formMethods
  const watchAllFields = watch()

  const formInitialValues = useRef({
    nickname: null,
    genderLabel: null,
    racialSelfDeclarationLabel: null,
  })

  useEffect(() => {
    if (!isLoading) {
      formInitialValues.current = personalDataFormPushes(formInitialValues, getValues, nicknameMessages, genderMessages, racialSelfDeclarationMessages)
    }
  }, [watchAllFields])

  useEffect(() => {
    const data = {
      personId,
      companyId,
      countryId,
      relations: [],
    }

    api
      .getConsultantInfo(data)
      .then(({ person: { name, nickname, gender, birthday, additionalInformation } }) => {
        reset({
          nickname: removeExtraSpace(nickname),
          gender: getGenderOptionById(gender),
          racialSelfDeclaration: getRacialSelfDeclarationOptionById(
            additionalInformation?.racialSelfDeclaration
          ),
        })
        setName(name)
        setBirthday(birthday)
        formInitialValues.current = {
          nickname,
          genderLabel: getGenderOptionById(gender)?.label,
          racialSelfDeclarationLabel: getRacialSelfDeclarationOptionById(
            additionalInformation?.racialSelfDeclaration
          )?.label
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [api, countryId, personId, reset])

  const handleSubmit = () => {
    try {
      setOpenSubmitLoading(true)

      const { nickname, gender, racialSelfDeclaration } = getValues()
      const updatedPersonInfo = {
        name,
        nickname: removeExtraSpace(nickname),
        gender: gender.value,
        birthday,
        racialSelfDeclaration: racialSelfDeclaration.value,
        additionalInformation: {
          racialSelfDeclaration: racialSelfDeclaration.value,
        },
        personId,
        companyId,
        countryId,
        businessModel: BUSINESS_MODELS.DIRECT_SALE,
        role: ROLES.CONSULTANT,
        functionId: FUNCTION_IDS.BEAUTY_CONSULTANT,
      }
      api
        .saveConsultantInfo(updatedPersonInfo)
        .then(() => {
          setModalStatus({
            message: actionMessages.successDialogMessage,
            isOpen: true,
            confirmationButtonCallback: onClose,
          })
          dataLayerPush('form_submit', { option_selected: 'personal data'})
        })
        .catch(() => {
          setModalStatus({
            message: actionMessages.unexpectedErrorDialogMessage,
            isOpen: true,
            confirmationButtonCallback: onCloseWithoutPreviousPage,
          })
        })
        .finally(() => {
          setOpenSubmitLoading(false)
        })
    } catch (e) {
      setModalStatus({
        message: actionMessages.unexpectedErrorDialogMessage,
        isOpen: true,
        confirmationButtonCallback: onCloseWithoutPreviousPage,
      })
      setOpenSubmitLoading(false)
    }
  }

  return (
    <Container
      nextButtonLabel={actionMessages.saveButton}
      onNextButtonClick={handleSubmit}
      disableNextButton={!formState.isValid}
      previousButtonLabel={actionMessages.previousButton}
      onPreviousButtonClick={goBackPreviousPage}
      isLoading={isLoading}
      nextButtonTextInline
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={componentClasses.container}>
        <Typography variant="h6" className={componentClasses.title}>
          {title}
        </Typography>
        <FormProvider {...formMethods}>
          <NicknameField id="nickname" messages={nicknameMessages} />
          <GenderField id="gender" messages={genderMessages} options={genderOptions} />
          <div className={componentClasses.group}>
            <RacialSelfDeclarationField
              id="racialSelfDeclaration"
              messages={racialSelfDeclarationMessages}
              options={racialSelfDeclarationOptions}
            />
            <IconPopover
              iconName="outlined-action-help"
              popoverText={racialSelfDeclarationTooltip}
              className={componentClasses.popover}
              iconSize="small"
            />
          </div>
        </FormProvider>
        <MaintenanceDialog
          message={modalStatus.message}
          confirmButtonName={actionMessages.dialogButtonName}
          open={modalStatus.isOpen}
          onClickConfirmButton={onClose}
        />
        <DialogWithLoader isOpen={openSubmitLoading} />
      </div>
    </Container>
  )
}
