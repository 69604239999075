import { HttpStatusCode } from '@/data/protocols/http'
import {
  PaymentAccountStatus,
  CheckPaymentAccount,
  CheckPaymentAccountParams
} from '@/domain/use-cases/digital-store/check-payment-account'
import { UnexpectedError } from '@/domain/errors'
import { HttpGetClient } from '@/data/protocols/http/http-get-client'
import { CountryId } from '@/domain/models/country'
import { BadRequestError } from '@/data/protocols/errors'
import { CompanyId } from '@/domain/models/company/company-id'

export type CheckPaymentAccountHttpRequestHeaders = {
  countryId: CountryId
  companyId: CompanyId
  'x-api-key': string
}

export type CheckPaymentAccountHttpRequestResponseBody = {
  accountExists?: boolean
}

export class RemoteCheckPaymentAccount
  implements CheckPaymentAccount {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
    CheckPaymentAccountHttpRequestHeaders,
    CheckPaymentAccountHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  load = async (
    params: CheckPaymentAccountParams,
  ): Promise<PaymentAccountStatus> => {
    const headers: CheckPaymentAccountHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      countryId: params.countryId,
      companyId: params.companyId,
    }

    const httpResponse = await this.httpGetClient.get({
      url: `${this.url}/${params.personId}`,
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
