import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

type ActionsLayoutProps={
  hasPreviousButton?: boolean
}
export const ActionsLayout = styled.div<ActionsLayoutProps>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => `${theme.sizes.small}px`};
  padding: ${({ theme }) => `${theme.sizes.small}px`};
  box-shadow: ${({ theme }) => `${theme.shadows[5]}`};
  background-color: ${({ theme }) => `${theme.color.surface}`};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  z-index: 4;
  justify-content: space-between;

  ${({ hasPreviousButton }) => !hasPreviousButton && 'grid-template-columns: 1fr;'}

  @media ${minDeviceSizeForMediaQueries.mobileXL} {
    align-items: stretch;
    padding-bottom: 0.5rem;
    padding-top: 0;
    max-width: 658px;
    margin: ${({ theme }) => `${theme.sizes.standard}px`} auto;
    flex-flow: row wrap;
    position: unset;
    box-shadow: unset;
    background-color: unset;
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
  }
`
