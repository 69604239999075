import {
 GetAgreementByProductParams, GetAgreementByProductResponse, UnlinkAgreementParams, UnlinkAgreementResponse, GetPendingAgreementByProductParams, GetPendingAgreementByProductResponse,
} from '@/domain/use-cases/agreement'
import {
 CheckDigitalStoreAvailabilityParams, GetDigitalStoreInfoParams, GetDigitalStoreInfoResponse, SaveDigitalStoreParams, UpdateDigitalStoreParams, UpdateDigitalStoreNameParams, UpdateDigitalStoreNameResponse
} from '@/domain/use-cases/digital-store'
import { UpdatePersonSubstatusOutput, UpdatePersonSubstatusParams } from '@/domain/use-cases/person'
import {
 makeCheckDigitalStoreAvailability, makeGetDigitalStoreInfo, makeRemoteUpdatePersonSubstatus, makeSaveDigitalStore, makeUpdateDigitalStore,
 makeUpdateDigitalStoreName,
} from '@/main/factories/remote'
import { makeRemoteGetAgreementByProduct, makeRemoteUnlinkAgreement, makeRemoteGetPendingAgreementByProduct } from '@/main/factories/remote/agreement'

export type DigitalStorePageApi = {
  getInfo: (data: GetDigitalStoreInfoParams) => Promise<GetDigitalStoreInfoResponse>
  checkNameAvailability: (data: CheckDigitalStoreAvailabilityParams) => Promise<boolean>
  save: (data: SaveDigitalStoreParams) => Promise<boolean>
  update: (data: UpdateDigitalStoreParams) => Promise<boolean>
  updateDigitalStoreName: (data: UpdateDigitalStoreNameParams) => Promise<UpdateDigitalStoreNameResponse>
  updatePersonSubstatus: (data: UpdatePersonSubstatusParams) => Promise<UpdatePersonSubstatusOutput>
  getAgreements: (data: GetAgreementByProductParams) => Promise<GetAgreementByProductResponse>
  getPendingAgreements: (data: GetPendingAgreementByProductParams) => Promise<GetPendingAgreementByProductResponse>
  unlinkAgreements: (data: UnlinkAgreementParams) => Promise<UnlinkAgreementResponse>
}

export const makeDigitalStorePageApi = (): DigitalStorePageApi => {
  const remoteGetDigitalStoreInfo = makeGetDigitalStoreInfo()
  const getDigitalStoreInfo = (data: GetDigitalStoreInfoParams) => remoteGetDigitalStoreInfo.get(data)

  const remoteCheckDigitalStoreAvailability = makeCheckDigitalStoreAvailability()
  const checkDigitalStoreAvailability = (data: CheckDigitalStoreAvailabilityParams) => remoteCheckDigitalStoreAvailability.check(data)

  const remoteSaveDigitalStore = makeSaveDigitalStore()
  const saveDigitalStore = (data: SaveDigitalStoreParams) => remoteSaveDigitalStore.save(data)

  const remoteUpdateDigitalStore = makeUpdateDigitalStore()
  const updateDigitalStore = (data: UpdateDigitalStoreParams) => remoteUpdateDigitalStore.update(data)

  const remoteUpdateDigitalStoreName = makeUpdateDigitalStoreName()
  const updateDigitalStoreName = (data: UpdateDigitalStoreNameParams) => remoteUpdateDigitalStoreName.updateName(data)

  const remoteUpdatePersonSubstatus = makeRemoteUpdatePersonSubstatus()
  const updatePersonSubstatus = (data: UpdatePersonSubstatusParams) => remoteUpdatePersonSubstatus.update(data)

  const remoteGetAgreement = makeRemoteGetAgreementByProduct()
  const getAgreementByProduct = (data: GetAgreementByProductParams) => remoteGetAgreement.get(data)

  const remoteGetPendingAgreement = makeRemoteGetPendingAgreementByProduct()
  const getPendingAgreementByProduct = (data: GetPendingAgreementByProductParams) => remoteGetPendingAgreement.get(data)

  const remoteUnlinkAgreement = makeRemoteUnlinkAgreement()
  const unlinkAgreement = (data: UnlinkAgreementParams) => remoteUnlinkAgreement.unlink(data)

  return {
    getInfo: getDigitalStoreInfo,
    checkNameAvailability: checkDigitalStoreAvailability,
    save: saveDigitalStore,
    update: updateDigitalStore,
    updateDigitalStoreName,
    updatePersonSubstatus,
    getAgreements: getAgreementByProduct,
    getPendingAgreements: getPendingAgreementByProduct,
    unlinkAgreements: unlinkAgreement,
  }
}
