import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { DigitalStoreMessages, DigitalStoreStatusPaymentMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { Button } from '@/main/components'
import { HelpInformation } from '@/main/components/help-information'
import {
  CenteredLoadingContainer,
  LoadingContainer,
} from '@/main/components/pages-structures/container.styles'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { IconName } from '@naturacosmeticos/natds-icons'
import { Alert, Typography } from '@naturacosmeticos/natds-react'
import { Icon } from '@naturacosmeticos/natds-web'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { useCheckPaymentAccountSWR } from '../../../../../../../hooks/swrServices/useCheckPaymentAccount'
import { HeaderV2 } from '../../../../../components/header-v2'
import { Option } from '../../../../../components/option'
import { Content } from '../../../../../digital-store-page.styles'
import { StyledIntroductionContainer } from '../../../vendors-linking-steps/mercado-pago/introduction-step/introduction-step.styles'
import {
  Container,
  StyledTextParagraph
} from '../digital-store-status-base-card.styles'
import { StyledCard } from '../../../../../components/styled-card'
import { getProfileUrl } from '../../../../../../../../utils/create-profile-url'



const AlertStyled = styled(Alert)`
  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`

const buttonStyle: React.CSSProperties = {
  borderRadius: '999px',
  textTransform: 'none',
}

const retryButtonStyle: React.CSSProperties = {
  textTransform: 'none',
  color: '#227BBD',
  paddingLeft: '5px'
}


export const DigitalStoreStatusMercadoPago = ({
  isLoading, digitalStoreStatus
}) => {
  const { tenantId, countryId: countryIdAlphaCode, personId, companyId } = useContext(IdentityInfoContext)
  const vendor = useTenantVendor(tenantId)
  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status
  const messages = usePageMessages(Page.DigitalStoreStatusPayment, { vendorName: vendor.name }).messages as DigitalStoreStatusPaymentMessages
  const headerMessages = usePageMessages(Page.DigitalStore, { vendorName: vendor.name }).messages as DigitalStoreMessages
  const statusMessages = messages.statusMessages[status]

  const { paymentAccountStatus, isLoadingPayment, hasError, reFetch } = useCheckPaymentAccountSWR({
    countryId: countryIdAlphaCode,
    companyId,
    personId,
  })

  const handleChangeEMail = () => {
    const profileUrl = getProfileUrl(countryIdAlphaCode)
    window.location.assign(profileUrl)
  }

  if (isLoading || isLoadingPayment || paymentAccountStatus?.accountExists) {
    return (
      <LoadingContainer data-testid="loading-container">
        <CenteredLoadingContainer>
          <ProgressIndicator />
        </CenteredLoadingContainer>
      </LoadingContainer>
    )
  }

  const isMobile = window.innerWidth < 720

  return (
    <div style={{ margin: isMobile? '20px 20px 80px 20px':'50px auto', maxWidth: '1080px' }}>
      <HeaderV2
        title={headerMessages.convergenceTitle}
        subheader={headerMessages.convergenceDescription}
      />
      <StyledCard>
        <Content>
          <h1 style={{ fontWeight: 500, fontSize: '20px', lineHeight: '25px', marginBottom: '25px' }}>
            {statusMessages.title}
          </h1>
          <Option position={2} title={statusMessages.shortTitle} description={statusMessages.subtitle} />

          {hasError ? (
            <Container style={{ flexDirection: 'column' }}>
              <StyledTextParagraph
                alignLeft
                content={statusMessages.error.title}
                hasMarginBottom
              />
              <StyledIntroductionContainer style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                <img src={vendor.brand} alt={vendor.name} />
                <span>
                  <Typography>
                    {statusMessages.error.message}
                  </Typography>
                  <Button
                    data-testid="button-retry"
                    variant="text"
                    size="medium"
                    style={retryButtonStyle}
                    onClick={reFetch}
                    startIcon={<Icon size='medium' name="outlined-action-cycle" />}
                  >
                    {statusMessages.buttons.retry}
                  </Button>
                </span>
              </StyledIntroductionContainer>
            </Container>
          ) : (
            <>
              <StyledTextParagraph
                alignLeft
                content={statusMessages?.message}
                hasMarginBottom
              />

              <Container style={{ justifyContent: 'flex-start', gap: '15px', flexWrap: 'wrap' }}>
                <Button
                  data-testid="button-change-email"
                  color="primary"
                  variant='outlined'
                  size="medium"
                  style={buttonStyle}
                  onClick={handleChangeEMail}
                >
                  {statusMessages?.buttons.changeEmail}
                </Button>
                <a
                  data-testid="mercado-pago-link"
                  style={{ display: 'flex', alignItems: 'center', gap: '8px', color: '#227bbd', textDecoration: 'none' }}
                  target='_blank'
                  rel='noreferrer'
                  href={statusMessages?.links.link}
                >
                  <StyledTextParagraph
                    content={statusMessages?.links.linkDescription}
                  />
                  <Icon size='micro' name={'outlined-navigation-opennew' as IconName} />
                </a>

              </Container>

              <AlertStyled color='warning' showIcon={false}>
                <Icon size='tiny' name={'filled-alert-warning' as IconName} />
                <StyledTextParagraph content={statusMessages?.warning.message} />
              </AlertStyled>
            </>
          )}

          <HelpInformation
            title={messages.contactInfoHelpTitle}
            content={messages.contactInfoHelp}
          />
        </Content>
      </StyledCard>
    </div>
  )
}
