import { HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { HttpGetClient } from '@/data/protocols/http/http-get-client'
import { CountryId } from '@/domain/models/country'
import { BadRequestError } from '@/data/protocols/errors'
import {
  GetPendingAgreementByProduct,
  GetPendingAgreementByProductParams,
  GetPendingAgreementByProductResponse,
} from '@/domain/use-cases/agreement'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { CountryIdAlpha2 } from '@/domain/models/country/country-id-alpha-2'
import { GetPendingAgreementByProductHttpRequestHeaders, GetPendingAgreementByProductHttpRequestResponseBody, GetPendingAgreementByProductHttpRequestQuery } from './remote-get-pending-agreement-by-product.protocols'

export class RemoteGetPendingAgreementByProduct
  implements GetPendingAgreementByProduct {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
      GetPendingAgreementByProductHttpRequestHeaders,
      GetPendingAgreementByProductHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) { }

  get = async (
    params: GetPendingAgreementByProductParams,
  ): Promise<GetPendingAgreementByProductResponse> => {
    const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()

    const country = params.countryId
    const countryId = country.length === 2
      ? convertCountryIdFromAlpha2ToAlpha3Presenter.handle({ countryId: country as unknown as CountryIdAlpha2 }) : country as CountryId

    const headers: GetPendingAgreementByProductHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      countryId,
      personId: params.personId,
      companyId: params.companyId,
    }

    const query: GetPendingAgreementByProductHttpRequestQuery = {
      products: params.products.join(','),
    }

    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(/:personId/i, params.personId),
      headers,
      query,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
