import { HttpStatusCode } from '@/data/protocols/http'
import {
  DigitalStoreStatus,
  FetchDigitalStoreStatuses,
  FetchDigitalStoreStatusesParams,
} from '@/domain/use-cases/digital-store/fetch-digital-store-statuses'
import { UnexpectedError } from '@/domain/errors'
import { HttpGetClient } from '@/data/protocols/http/http-get-client'
import { CountryId } from '@/domain/models/country'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { BadRequestError } from '@/data/protocols/errors'
import { CompanyId } from '@/domain/models/company/company-id'

export type FetchDigitalStoreStatusesHttpRequestHeaders = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
  'x-api-key': string
  lang: string
}

export type FetchDigitalStoreStatusesHttpRequestResponseBody = {
  [key: string]: {
    title: string
    message: string
    status: DigitalStoreCurrentStatus
  }
}

export class RemoteFetchDigitalStoreStatuses
  implements FetchDigitalStoreStatuses {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
      FetchDigitalStoreStatusesHttpRequestHeaders,
      FetchDigitalStoreStatusesHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  load = async (
    params: FetchDigitalStoreStatusesParams,
  ): Promise<DigitalStoreStatus | {}> => {
    const headers: FetchDigitalStoreStatusesHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      countryId: params.countryId,
      personId: params.personId,
      companyId: params.companyId,
      lang: params.lang,
    }
    const query = {
      personIds: params.personId,
    }
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      headers,
      query,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body[params.personId]
          ? httpResponse.body[params.personId]
          : httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
