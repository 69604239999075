import React, { useState } from 'react'

type HeaderProps = {
    url: string;
    tooltipText: string
    disableCopy?: boolean
}

export const UrlCopyButton = ({ url, tooltipText, disableCopy }: HeaderProps) => {
    const [isCopied, setIsCopied] = useState(false) // Estado para controlar a visibilidade do tooltip

    const handleCopyUrl = () => {
        if (disableCopy) return // Se a cópia estiver desabilitada, não faz nada
        navigator.clipboard.writeText(url)
            .then(() => {
                setIsCopied(true) // Mostra o tooltip
                setTimeout(() => setIsCopied(false), 2000) // Esconde o tooltip após 2 segundos
            })
            .catch((err) => {
                console.error('Erro ao copiar URL:', err)
            })
    }

    const buttonStyle: React.CSSProperties = {
      cursor: disableCopy ? 'default' : 'pointer', // Muda o cursor para indicar que o botão está desabilitado
      background: 'none',
      border: 'none',
      color: '#1267CF',
      fontSize: '16px',
      padding: 0,
      marginRight: '15px',
      wordBreak: 'break-all',
      textAlign: 'left'
    }

    return (
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <button type="button" style={buttonStyle} onClick={handleCopyUrl}>{url}</button>
        {isCopied && (
        <div
          style={{
                        position: 'absolute',
                        top: '-30px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        whiteSpace: 'nowrap',
                    }}
        >
          {tooltipText}
        </div>
            )}
      </div>
    )
}
