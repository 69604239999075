import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { DigitalStoreMessages, DigitalStoreStatusDocumentValidationMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { HelpInformation } from '@/main/components/help-information'
import {
  CenteredLoadingContainer,
  LoadingContainer,
} from '@/main/components/pages-structures/container.styles'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Alert } from '@naturacosmeticos/natds-react'
import React from 'react'
import styled from 'styled-components'
import { HeaderV2 } from '../../components/header-v2'
import { Option } from '../../components/option'
import { StyledCard } from '../../components/styled-card'
import { Content } from '../../digital-store-page.styles'
import { StyledTextParagraph } from './components/digital-store-status/digital-store-status-base-card.styles'

const AlertStyled = styled(Alert)`
  max-width: 550px;
`


export const DigitalStoreUnableToSell = ({
  isLoading, digitalStoreStatus
}) => {
  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status
  const messages = usePageMessages(Page.DigitalStoreStatusDocumentValidation).messages as DigitalStoreStatusDocumentValidationMessages
  const headerMessages = usePageMessages(Page.DigitalStore).messages as DigitalStoreMessages
  const statusMessages = messages.statusMessages[status]
  const isMobile = window.innerWidth < 720

  if (isLoading) {
    return (
      <LoadingContainer data-testid="loading-container">
        <CenteredLoadingContainer>
          <ProgressIndicator />
        </CenteredLoadingContainer>
      </LoadingContainer>
    )
  }

  return (
    <div style={{ margin: isMobile ? '20px 20px 80px 20px' : '50px auto', maxWidth: '1080px' }}>
      <HeaderV2
        title={headerMessages.convergenceTitle}
        subheader={headerMessages.convergenceDescription}
      />
      <StyledCard>
        <Content>
          <h1 style={{ fontWeight: 500, fontSize: '20px', lineHeight: '25px', marginBottom: '25px' }}>
            {statusMessages.title}
          </h1>
          <Option
            position={4}
            title={statusMessages.shortTitle}
          />
          <AlertStyled
            color="custom"
            customBorderColor="#BDBDBD"
            customIcon="filled-alert-notification"
            title={statusMessages.subtitle}
            type='outlined'
          >
            <StyledTextParagraph
              alignLeft
              content={statusMessages.message}
            />
          </AlertStyled>

          <HelpInformation
            title={messages.contactInfoHelpTitle}
            content={messages.contactInfoHelp}
          />
        </Content>
      </StyledCard>
    </div>
  )
}
