import { useContext } from 'react'
import { MiGroupRedPageContext } from '../contexts/mi-group-red-page-context'

interface MiGroupRedPageContextValue {
  isMiGroupRedPage: boolean
}

export const useMiGroupRedPage = (): MiGroupRedPageContextValue => {
  const context = useContext(MiGroupRedPageContext)

  if (context === undefined) {
    throw new Error('useMiGroupRedPage must be used within a MiGroupRedPageProvider')
  }

  return context
}

