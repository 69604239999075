import { IdentityInfoContext, NavigationContext, NavigationContextProps } from '@/main/contexts'
import { ContainerV2 } from '@/main/components/pages-structures/container-mx'
import { DigitalStoreMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { DigitalStoreConfigs } from '@/domain/models/tenant/tenant-configs'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react'
import { CustomChangeEvent } from '@/main/components/text-field/helper/handle-onchange'
import { useForm } from 'react-hook-form'
import { getEloToggleByCountry } from '@/utils/getEloToggle'
import { AgreementProduct } from '../../../domain/models/agreement/agreement-product'
import {
  Content,
} from './digital-store-page.styles'
import { DigitalStorePageApi } from './api/make-digital-store-page-api'
import NameValidation from './components/name-validation'
import { ExtraEntries } from './components/extra-entries'
import SaveErrorDialog from './components/save-error-dialog'
import { HeaderV2 } from './components/header-v2'
import { Option } from './components/option'
import { StyledCard } from './components/styled-card'
import { AgreementsTerms } from './components/agreements-terms/agreements-terms'
import { useDigitalStore } from './hooks/use-digital-store'
import { DigitalStoreUrlWithEdit } from './pages/digital-store-status-page/components/digital-store-url-with-edit'

export type DigitalStorePageProps = {
  convergence?: boolean,
  onSave?: () => void,
  refetchPersonData?: () => void,
  digitalStoreName?: string,
  api: DigitalStorePageApi,
  isNotStartedStatus?: boolean
}

type Debounce = {
  delay: number
  countdown: any
}

export type DigitalStoreFormInputs = {
  clabe: string,
  rfc: string,
}[]

const delay = (size: number) => new Promise((resolve) => {
  setTimeout(() => resolve(null), size)
})

export const DigitalStorePageV2 = ({ convergence = false, onSave, api, digitalStoreName = null, isNotStartedStatus, refetchPersonData }: DigitalStorePageProps) => {
  const {
    tenantId,
  } = useContext(IdentityInfoContext)

  const {
    goToNextPage,
    goToPreviousPage,
  } = useContext<NavigationContextProps>(NavigationContext)

  const {
    acceptTermsStrategy,
    showWarningAfterGetSubmitError,
    digitalStoreNameMaxOfChars,
    shouldUnlinkAgreements,
  } = useTenantConfigs(tenantId, Page.DigitalStore) as DigitalStoreConfigs

  const {
    name: vendorName,
  } = useTenantVendor(tenantId)

  const {
    document: documentMessages,
    ...messages
  } = usePageMessages(Page.DigitalStore, { vendorName }).messages as DigitalStoreMessages

  const formMethods = useForm<DigitalStoreFormInputs>({
    mode: 'onTouched',
  })

  const {
    getValues,
    setValue,
    formState,
    trigger,
  } = formMethods

  const [isSaveErrorDialogOpen, setIsSaveErrorDialogOpen] = useState(false)
  const [editActive, toggleEdit] = useState(digitalStoreName !== null)
  const [digitalStoreDefault, setDigitalStoreDefault] = useState(digitalStoreName !== null)

  const {
    save,
    update,
    isSaved,
    errorOnCheckAvailability,
    isLoading,
    agreements,
    errorOnSave,
    setHasError,
    isAvailable,
    helperTextKey,
    isTermsAccepted,
    setHelperTextKey,
    unlinkAgreements,
    savedExtraEntries,
    shouldDisableInput,
    checkAvailability,
    setIsTermsAccepted,
    alreadyHadSavedName,
    isLoadingAgreements,
    shouldHideAgreements,
    shouldShowSkipButton,
    hasSavedExtraEntries,
    shouldEnableSaveButton,
    userNeedsToAcceptTerms,
    currentDigitalStoreName,
    setUserNeedsToAcceptTerms,
    shouldDisableVerifyButton,
    setCurrentDigitalStoreName,
    extraEntriesFromTenantConfig,
    hasExtraEntriesFromTenantConfig,
    showLoadWhileSavingDigitalStore,
  } = useDigitalStore({
    api,
    convergence,
    extraEntriesForm: formState,
    digitalStoreDefault
  })

  useEffect(() => {
    if (errorOnSave) {
      setIsSaveErrorDialogOpen(true)
    }
  }, [errorOnSave])

  useEffect(() => {
    const hasExtraEntries = hasExtraEntriesFromTenantConfig && hasSavedExtraEntries

    const setExtraEntriesValues = (extraEntries: {}) => {
      Object.keys(extraEntries).forEach((entryKey) => {
        setValue(entryKey, extraEntries[entryKey])
      })
      trigger()
    }

    if (hasExtraEntries) {
      setExtraEntriesValues(savedExtraEntries)
    }
  }, [hasExtraEntriesFromTenantConfig, hasSavedExtraEntries, savedExtraEntries, setValue, trigger])

  useEffect(() => {
    if (isSaved && !errorOnSave) {
      if (!convergence) {
        goToNextPage()
      } else {
        onSave()
        refetchPersonData()
      }
    }
  }, [convergence, goToNextPage, isSaved, onSave, errorOnSave])

  useEffect(() => {
    if (digitalStoreName) {
      setCurrentDigitalStoreName(digitalStoreName)
    }
  }, [digitalStoreName])

  const handleCheckAvailability = () => {
    checkAvailability(currentDigitalStoreName)
  }


  const handleSaveDigitalStore = () => {
    const extraEntriesFromForm = getValues() || {}
    if (isNotStartedStatus) {
      save(extraEntriesFromForm)
    } else {
      update(extraEntriesFromForm)
    }
  }

  const debounce = useMemo<Debounce>(() => ({
    delay: 200,
    countdown: 0,
  }), [])

  const handleOnChangeInput = (event: CustomChangeEvent) => {
    const { value } = event.target

    setDigitalStoreDefault(false)

    const hasValidNameCharacters = (name: string) => (
      name.match(/^[a-z0-9_]+$/i) !== null
    )

    const hassPassedMaxNumOfChars = (name: string, maxNum:number) => {
      let hassPassed = false
      if (maxNum) {
        hassPassed = name.length > maxNum
      }
      return hassPassed
    }

    const onDoneHandleChangeInput = (error: boolean, helperTextKey: string) => {
      setHasError(error)
      setHelperTextKey(helperTextKey)
    }

    const resetDigitalStoreStates = async () => {
      await delay(100)
      onDoneHandleChangeInput(false, '')
    }

    setCurrentDigitalStoreName(value)

    clearTimeout(debounce.countdown)
    debounce.countdown = setTimeout(() => {
      if (hasValidNameCharacters(value) && !hassPassedMaxNumOfChars(value, digitalStoreNameMaxOfChars)) {
        resetDigitalStoreStates()
      } else {
        onDoneHandleChangeInput(true, 'invalidNameSpace')
      }
    }, debounce.delay)
  }

  const handleNextPage = () => {
    if (alreadyHadSavedName && !convergence) {
      goToNextPage()
    } else {
      handleSaveDigitalStore()
    }
  }

  const handleSkipButtonClick = async () => {
    try {
      if (shouldUnlinkAgreements) {
        await unlinkAgreements()
      }

      goToNextPage()
    } catch (e) {
      console.error(e)
    }
  }

  const setEdit = () => {
    toggleEdit(!editActive)
  }

  const checkDigitalStoreNameState = () => {
    if (helperTextKey === '' && errorOnCheckAvailability) {
      return undefined
    }
    if (errorOnCheckAvailability) {return 'error'}
    if (isAvailable) {return 'success'}
    if (digitalStoreDefault) {return 'success'}
    return undefined
  }

  const getErrorMessages = useCallback((): string | undefined => {
    const errorIndex: string[] = ['unavailableName', 'invalidNameSpace', 'genericError', 'errorSaving']
    if (!!helperTextKey && errorIndex.includes(helperTextKey)) {
      return messages[helperTextKey]
    }
    return undefined
  }, [helperTextKey, messages, errorOnCheckAvailability])

  const isMobile = window.innerWidth < 720

  const showAgreements = isLoadingAgreements || agreements.length > 0

  return (
    <>
      <div style={{ margin: isMobile? '20px 20px 80px 20px':'50px' }}>

        {!showLoadWhileSavingDigitalStore && (
        <HeaderV2
          title={!convergence ? messages.title : messages.convergenceTitle}
          subheader={!convergence ? messages.description : messages.convergenceDescription}
        />
)}
        <StyledCard isLoading={showLoadWhileSavingDigitalStore}>
          <ContainerV2
            previousButtonLabel={!convergence ? messages.previousButtonLabel : ''}
            onPreviousButtonClick={goToPreviousPage}
            nextButtonLabel={messages.nextButtonLabel}
            onNextButtonClick={handleNextPage}
            disableNextButton={!shouldEnableSaveButton}
            showSkipButton={shouldShowSkipButton}
            skipButtonLabel={messages.skipButtonLabel}
            onSkipButtonClick={handleSkipButtonClick}
            isLoading={showLoadWhileSavingDigitalStore}
          >
            <Content>
              <h1 style={{ fontWeight: 500, fontSize: '20px', lineHeight: '25px' }}>{messages.title}</h1>
              <Option position={1} title={messages.advantages.firstItemTitle} description={messages.advantages.firstItemDescription} />
              <SaveErrorDialog
                open={isSaveErrorDialogOpen}
                message={messages.dialogError.message}
                onClickOk={() => setIsSaveErrorDialogOpen(false)}
                okButtonLabel={messages.dialogError.buttonOk}
                shouldRender={showWarningAfterGetSubmitError}
              />
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <div style={{ flex: !editActive && 1, minWidth: isMobile ? '250px' : '300px', marginBottom: isMobile ? '15px' : '0', marginTop: '25px', marginRight: '25px' }}>
                  {digitalStoreName && editActive && <DigitalStoreUrlWithEdit toggleFunction={setEdit} disableCopy tooltipText={messages.editDigitalStoretooltipText} buttonText={messages.editDigitalStoreNameButton} text2={messages.editDigitalStoreNameOption} url={messages.baseUrl} digitalStoreName={digitalStoreName}  />}
                  {!editActive && (
                  <NameValidation
                    label={messages.nameLabel}
                    tooltip={!convergence ? messages.nameTip : undefined}
                    buttonLabel={messages.verifyButtonLabel}
                    buttonIsLoading={isLoading}
                    errorMessage={getErrorMessages()}
                    placeholder={messages.validateNamePlaceHolder}
                    buttonOnClick={() => handleCheckAvailability()}
                    buttonOnChange={() => setHelperTextKey('')}
                    onChangeInput={handleOnChangeInput}
                    isMexConvergence
                    shouldButtonBeDisabled={shouldDisableVerifyButton}
                    disabled={shouldDisableInput}
                    isSuccess={checkDigitalStoreNameState() === 'success'}
                    invalid={checkDigitalStoreNameState() === 'error'}
                    successMessage={checkDigitalStoreNameState() === 'success' ? messages.availableName : undefined}
                    inputValue={currentDigitalStoreName}
                  />
)}
                </div>
                <div style={{ flex: 1, minWidth: '300px', maxWidth: '400px'}}>
                  <ExtraEntries
                    isMexConvergence
                    shouldRender={hasExtraEntriesFromTenantConfig}
                    disableFields={isLoading}
                    fields={extraEntriesFromTenantConfig}
                    fieldsTexts={documentMessages}
                    formMethods={formMethods}
                  />
                </div>

              </div>
              {showAgreements && (
              <AgreementsTerms
                isLoading={isLoadingAgreements}
                isMexConvergence
                shouldHide={shouldHideAgreements}
                acceptTermsStrategy={acceptTermsStrategy}
                acceptTermsProps={{
                agreements,
                setUserNeedsToAcceptTerms,
                shouldFetch: false,
                shouldHide: !userNeedsToAcceptTerms,
                termsInfo: messages.terms.termsInfo,
                dialogInfo: {
                  title: messages.terms.modalTitle,
                  acceptText: messages.terms.modalAccept,
                  closeText: messages.terms.modalClose,
                },
                products: [AgreementProduct.NATURA],
                checkbox: {
                  color: getEloToggleByCountry()? 'primary':'secondary',
                  normalLabel: messages.terms.agreementLabel,
                  highlightedLabel: messages.terms.agreementLabelLink,
                  value: isTermsAccepted,
                  onChange: (isAccepted) => setIsTermsAccepted(isAccepted),
                },
              }}
              />
)}

            </Content>
          </ContainerV2>
          <div style={{ display: showLoadWhileSavingDigitalStore ? 'none': '', width: '100%', borderTop: '1px solid #A4A4A4', marginTop: isMobile ? '15px' : '', marginBottom: '15px' }} />
          <Option position={2} hide={showLoadWhileSavingDigitalStore} title={messages.advantages.secondItemTitle} />
          <Option position={3} hide={showLoadWhileSavingDigitalStore} title={messages.advantages.thirdItemTitle} />
          <Option position={4} hide={showLoadWhileSavingDigitalStore} title={messages.advantages.fourthItemTitle} />
        </StyledCard>
      </div>

    </>
  )
}
