import { RemoteUpdateDigitalStoreName } from '@/data/use-cases/digital-store'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { UpdateDigitalStoreName } from '@/domain/use-cases/digital-store'

export const makeUpdateDigitalStoreName = ():UpdateDigitalStoreName => {
  const apiUrl = '/digital-store/update-store-name/:personId'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteUpdateDigitalStoreName(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
}
