import { Icon } from '@naturacosmeticos/natds-web'
import React, { useEffect, useState } from 'react'

import { MaintenanceRegisterMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { MaintenanceRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { Button } from '@/main/components'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { dataLayerPush } from '@/analytics/helpers/dataLayerPush'
import { PhoneNumber } from '../fields/phone-number'

type PhoneNumberProps = {
  tenantId: TenantId
  hasSecondaryPhone?: boolean
  onRemoveSecondaryPhone?: (callback: Function) => void
}

export const OldPhonesInputs = ({
  tenantId,
  hasSecondaryPhone,
  onRemoveSecondaryPhone,
}: PhoneNumberProps) => {
  const [showRemoveButton, setShowRemoveButton] = useState(hasSecondaryPhone)

  const messages = usePageMessages(Page.MaintenanceRegister).messages as MaintenanceRegisterMessages

  const phoneMessages = {
    label: messages.phoneNumber?.label,
    secondaryPhoneLabel: messages.phoneNumber.secondaryPhoneLabel,
    placeholder: messages.phoneNumber?.placeholder,
    requiredErrorMessage: messages.requiredErrorMessage,
    firstDigitErrorMessage: messages.firstDigitErrorMessage,
    lengthErrorMessage: messages.lengthErrorMessage,
    addPhoneNumber: messages.phoneNumber.addPhoneNumber,
    removePhoneNumber: messages.phoneNumber.removePhoneNumber,
    telephoneInvalid: messages.telephoneInvalid,
  }

  const { showPhoneNumberSecondary, phoneNumberOptions, phoneNumberSecondaryOptions } = useTenantConfigs(
    tenantId,
    Page.MaintenanceRegister
  ) as MaintenanceRegisterConfigs

  const handlePhoneNumberButton = () => {
    if (showRemoveButton) {
      if (onRemoveSecondaryPhone) {
        onRemoveSecondaryPhone(() => {
          dataLayerPush('option_phone', { option_selected: 'remove phone' })
          setShowRemoveButton((prev) => !prev)
        })
      } else {
        setShowRemoveButton(false)
      }
      return
    }

    dataLayerPush('option_phone', { option_selected: 'add phone' })
    setShowRemoveButton(true)
  }

  useEffect(() => {
    setShowRemoveButton(hasSecondaryPhone)
  }, [hasSecondaryPhone])

  return (
    <>
      <PhoneNumber
        id="phoneNumber"
        name="phoneNumber"
        messages={phoneMessages}
        rules={{ required: true }}
        mask={phoneNumberOptions.mask}
        options={{
          maxLength: phoneNumberOptions.maxLength,
          minLength: phoneNumberOptions.minLength,
        }}
        mobileRegex={phoneNumberOptions?.mobileRegex}
      />

      {showPhoneNumberSecondary && (
        <>
          {showRemoveButton && (
            <PhoneNumber
              id="phoneNumberSecondary"
              name="phoneNumberSecondary"
              messages={{ ...phoneMessages, label: phoneMessages.secondaryPhoneLabel }}
              rules={{ required: true }}
              mask={phoneNumberSecondaryOptions?.mask}
              options={{
                maxLength: phoneNumberSecondaryOptions?.maxLength,
                minLength: phoneNumberSecondaryOptions?.minLength,
              }}
              mobileRegex={phoneNumberSecondaryOptions?.mobileRegex}
            />
          )}
          <Button
            startIcon={<Icon name={showRemoveButton ? 'filled-action-subtract' : 'filled-action-add'} />}
            onClick={handlePhoneNumberButton}
          >
            {showRemoveButton ? phoneMessages.removePhoneNumber : phoneMessages.addPhoneNumber}
          </Button>
        </>
      )}
    </>
  )
}
