import { makeStyles } from '@naturacosmeticos/natds-web'
import { Theme } from '@material-ui/core/styles'

type ControlledSelectStylesProps = {
  disabled?: boolean
}

export const useControlledSelectStyles = makeStyles<Theme, ControlledSelectStylesProps>((theme) => ({
  container: {
    cursor: ({ disabled }) => disabled && 'no-drop !important',
    padding: `${theme.spacing(1.5)}px 0`,
  },
  controller: {
    borderRadius: '16px',
  },
  label: {
    marginBottom: '5px',
    paddingBottom: '4px',
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: `${theme.spacing(0.15)}em`,
    letterSpacing: '0.11px',
    fontSize: theme.typography.fontSize,
  },
}))
