import React from 'react'
import { ICardProps } from '@/main/components'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { ActionsLayout } from '@/main/components/Layout/ActionsLayout'
import { GayaButton as Button } from '@naturacosmeticos/natds-react'
import {
  useContainerStyles,
  ActionButton,
  LoadingContainer,
  CenteredLoadingContainer,
} from '@/main/components/pages-structures/container.styles'
import { getEloToggleByCountry } from '@/utils/getEloToggle'

type handleFunctionType = () => void

type ContainerProps = {
  showSkipButton?: boolean
  showAlternativeFlowButton?: boolean
  skipButtonLabel?: string
  onSkipButtonClick?: handleFunctionType
  disableSkipButton?: boolean
  disableAlternativeFlowButton?: boolean
  nextButtonLabel?: string
  onNextButtonClick?: handleFunctionType
  disableNextButton?: boolean
  previousButtonLabel?: string
  alternativeFlowButtonLabel?: string
  onPreviousButtonClick?: handleFunctionType
  onAlternativeFlowButtonClick?: handleFunctionType
  isLoading?: boolean
  nextButtonTextInline?: boolean
  nextButtonDataTestId?: string
} & ICardProps

export const ContainerV2: React.FC<ContainerProps> = ({
  nextButtonLabel = '',
  nextButtonDataTestId = 'next-button',
  onNextButtonClick,
  disableNextButton = false,
  previousButtonLabel,
  onPreviousButtonClick,
  showSkipButton = false,
  skipButtonLabel,
  onSkipButtonClick,
  disableSkipButton = false,
  showAlternativeFlowButton = false,
  alternativeFlowButtonLabel,
  onAlternativeFlowButtonClick,
  disableAlternativeFlowButton = false,
  isLoading = false,
  children: cardChildren,
  nextButtonTextInline,
}) => {
  const hasPreviousButton = Boolean(previousButtonLabel)
  const classes = useContainerStyles({ hasPreviousButton, showSkipButton, showAlternativeFlowButton, nextButtonTextInline })

  if (isLoading) {
    return (
      <LoadingContainer data-testid="loading-container">
        <CenteredLoadingContainer>
          <ProgressIndicator />
        </CenteredLoadingContainer>
      </LoadingContainer>
    )
  }

  const isMobile = window.innerWidth < 460

  return (
    <>
      <div data-testid="main-2">
        {showSkipButton && (
          <Button
            key="skip"
            testID="skip-button"
            className={classes.skipButton}
            onClick={onSkipButtonClick}
            disabled={disableSkipButton}
          >
            {skipButtonLabel}
          </Button>
        )}
        <div key="card-children" className={classes.cardChildren}>
          {cardChildren}
          <ActionsLayout style={{ margin: isMobile ? '20px 0 0 0' : '30px 0 40px 0' }} className={classes.actionsLayout} role="navigation" hasPreviousButton={hasPreviousButton}>
            {hasPreviousButton && (
            <ActionButton
              testID="previous-button"
              onClick={onPreviousButtonClick}
              variant={getEloToggleByCountry() ? 'text': 'outlined'}
              className={classes.previousButton}
            >
              {previousButtonLabel}
            </ActionButton>
        )}
            {showAlternativeFlowButton && (
            <Button
              variant="outlined"
              key="alternativeFlow"
              testID="alternative-flow-button"
              className={classes.alternativeFlowButton}
              onClick={onAlternativeFlowButtonClick}
              disabled={disableAlternativeFlowButton}
            >
              {alternativeFlowButtonLabel}
            </Button>
        )}
            <ActionButton
              testID={nextButtonDataTestId}
              className={`${classes.nextButton} ${classes.buttonInline}`}
              onClick={onNextButtonClick}
              disabled={disableNextButton}
              variant={getEloToggleByCountry() ?  'outlined' : 'contained'}
            >
              {nextButtonLabel}
            </ActionButton>
          </ActionsLayout>
        </div>
      </div>

    </>
  )
}
