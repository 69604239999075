import { MaintenanceSecurityValidationMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { CardContainer } from '@/main/components/status-card-container/status-card-container.styles'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Typography, GayaButton as Button } from '@naturacosmeticos/natds-react'
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@naturacosmeticos/natds-web'
import React from 'react'
import { DescriptionContainer, StyleButtons, TitleContainer } from './maintenance-security-validation.styles'

type MaintenanceSecurityValidationContactLeaderDialogParans = {
  open: boolean,
  onClickConfirmButton?: () => void,
}

export const MaintenanceSecurityValidationContactLeaderDialog = ({ open, onClickConfirmButton }: MaintenanceSecurityValidationContactLeaderDialogParans) => {
  const messages = usePageMessages(Page.MaintenanceSecurityValidation, {
    email: '',
    sms: '',
  }).messages as MaintenanceSecurityValidationMessages

  return (
    <Dialog
      maxWidth="xs"
      open={open}
    >
      <DialogContent>
        <CardContainer>
          <TitleContainer>
            <Typography variant="heading6">
              {messages.contactYourLeader}
            </Typography>
          </TitleContainer>
          <DescriptionContainer>
            <Typography variant="body1">
              {messages.cnwithoutBeingAbleToAccessYourContactsMessage}
            </Typography>
          </DescriptionContainer>
        </CardContainer>
      </DialogContent>
      <StyleButtons>
        <DialogActions>
          <Button
            onClick={onClickConfirmButton}
            color="primary"
            size="medium"
            variant="contained"
          >
            {messages.dialogButtonUnderstoodName}
          </Button>
        </DialogActions>
      </StyleButtons>
    </Dialog>
  )
}
