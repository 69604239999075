import React from 'react'
import {
  Dialog,
  DialogFooter,
  DialogBody,
  GayaButton,
  Typography,
} from '@naturacosmeticos/natds-react'
import { dataLayerPush } from '@/analytics/helpers/dataLayerPush'

type MaintenanceDialog = {
  open: boolean
  confirmButtonName: string
  message?: string
  onClickConfirmButton?: () => void
  cancelButtonName?: string
  onClickCancelButton?: () => void
  Children?
}

export const MaintenanceDialog = ({
  confirmButtonName,
  open,
  message,
  onClickConfirmButton,
  cancelButtonName,
  onClickCancelButton,
  Children,
}: MaintenanceDialog) => {
  const handleConfirmation = (buttonName: string) => {
    dataLayerPush('continue_form', { option_selected: buttonName.toLowerCase() })
    if (onClickConfirmButton) {
      onClickConfirmButton()
    }
  }

  const handleCancel = (buttonName: string) => {
    dataLayerPush('continue_form', { option_selected: buttonName.toLowerCase() })
    if (onClickCancelButton) {
      onClickCancelButton()
    }
  }
  return (
    <Dialog
      testID='maintenance-dialog'
      role="dialog"
      open={open}
    >
      <DialogBody>
        {
        Children
          ? (
            <Children />
          )
          : (
            <Typography variant="body1">
              {message}
            </Typography>
          )
      }
      </DialogBody>
      <DialogFooter
        secondaryButton={cancelButtonName && (
        <GayaButton
          variant="outlined"
          onClick={() => handleCancel(cancelButtonName)}
          size="semiX"
        >
          {cancelButtonName}
        </GayaButton>
      )}
        primaryButton={(
          <GayaButton
            color="primary"
            onClick={() => handleConfirmation(confirmButtonName)}
            size="semiX"
            variant="contained"
          >
            {confirmButtonName}
          </GayaButton>
        )}
      />
    
    </Dialog>
)
}
