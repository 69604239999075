import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { DigitalStoreMessages, DigitalStoreStatusDocumentValidationMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { HelpInformation } from '@/main/components/help-information'
import {
  CenteredLoadingContainer,
  LoadingContainer,
} from '@/main/components/pages-structures/container.styles'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import React, { useContext, useEffect, useState } from 'react'
import { EventResponse, SeguridataFormStatus } from '@/domain/models/digital-store/digital-store-pending-document-validation-types'
import { makeGenerateCredentials } from '../../../../factories/remote/document-validation/make-remote-generate-credentials'
import { HeaderV2 } from '../../components/header-v2'
import { Option } from '../../components/option'
import { StyledCard } from '../../components/styled-card'
import { Content } from '../../digital-store-page.styles'
import { DefaultStatusComponent, ErrorStatusComponent, SuccessStatusComponent } from './components/digital-store-status/document-validation-status'
import { IframeForm } from './components/iframe-form'


export const DigitalStorePendingDocumentValidation = ({
  isLoading, digitalStoreStatus
}) => {
  const { countryId, personId, companyId } = useContext(IdentityInfoContext)
  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status
  const messages = usePageMessages(Page.DigitalStoreStatusDocumentValidation).messages as DigitalStoreStatusDocumentValidationMessages
  const headerMessages = usePageMessages(Page.DigitalStore).messages as DigitalStoreMessages
  const statusMessages = messages.statusMessages[status]
  const isMobile = window.innerWidth < 720
  const callbackUrl = process.env.REGISTERS_BFF_URL

  const [iframeStatus, setIframeStatus] = useState<{ open: boolean, iframePayload?: { [key: string]: string } }>()
  const [pageLoading, setPageLoading] = useState<boolean>(isLoading)
  const [pageStatus, setPageStatus] = useState<'default' | 'success' | 'error'>('default')

  const handleIframeMessages = (event: MessageEvent) => {
    if (event.origin === callbackUrl) {
    const {formStatus} = event.data as EventResponse
      
    if (formStatus && formStatus === SeguridataFormStatus.APPROVED) {
        setPageStatus('success')
        setIframeStatus({ open: false })
        return
      }
      setPageStatus('error')
      setIframeStatus({ open: false })
    }
  }
  
  useEffect(() => {
    window.addEventListener('message', handleIframeMessages)
    return () => window.removeEventListener('message', handleIframeMessages)
  }, [])

  const handleOpenIframe = async () => {
    try {
      setPageLoading(true)

      const service = makeGenerateCredentials()
      const { data } = await service.get({
        countryId,
        companyId,
        personId,
      })

      const iframePayload = {
        url: data.weblink,
        tokenId: data.authentication.tokenId,
        productId: data.authentication.productId,
        requestId: data.authentication.requestId,
        returnurl: `${callbackUrl}/registers/accounts/registers/v1/document-validation-public/seguridata-callback/${personId}`,
        language: 'es_ES'
      }

      setPageLoading(false)
      setIframeStatus({ open: true, iframePayload })

    } catch (error) {
      setPageLoading(false)
      setPageStatus('error')
    }
  }

  const pageStatusComponents = {
    default: <DefaultStatusComponent statusMessages={statusMessages} onClickFunction={handleOpenIframe} />,
    error: <ErrorStatusComponent statusMessages={statusMessages} onClickFunction={handleOpenIframe} />,
    success: <SuccessStatusComponent statusMessages={statusMessages} />,
  }

  if (pageLoading) {
    return (
      <LoadingContainer data-testid="loading-container">
        <CenteredLoadingContainer>
          <ProgressIndicator />
        </CenteredLoadingContainer>
      </LoadingContainer>
    )
  }

  return (
    <div style={{ margin: isMobile ? '20px 20px 80px 20px' : '50px auto', maxWidth: '1080px' }}>
      <HeaderV2
        title={headerMessages.convergenceTitle}
        subheader={headerMessages.convergenceDescription}
      />
      {iframeStatus?.open ? (
        <IframeForm {...iframeStatus} />
      ) : (
        <StyledCard>
          <Content>
            <h1 style={{ fontWeight: 500, fontSize: '20px', lineHeight: '25px', marginBottom: '25px' }}>
              {statusMessages.title}
            </h1>
            <Option
              position={3}
              title={statusMessages.shortTitle}
            />
            {pageStatusComponents[pageStatus]}

            <div style={{ width: '100%', borderTop: '1px solid #A4A4A4', marginTop: '16px' }} />

            <HelpInformation
              title={messages.contactInfoHelpTitle}
              content={messages.contactInfoHelp}
            />
          </Content>
        </StyledCard>
      )}
    </div>
  )
}
