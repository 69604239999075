import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteGenerateCredentials } from '@/data/use-cases/document-validation'


export const makeGenerateCredentials = () => {
  const apiUrl = '/document-validation/generate-weblink/:personId'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteGenerateCredentials = new RemoteGenerateCredentials(
    apiUrl,
    httpGetClient,
    xApiKey,
  )

  return remoteGenerateCredentials
}
