import { HttpStatusCode, HttpPatchClient } from '@/data/protocols/http'
import {
  UpdateDigitalStoreName,
  UpdateDigitalStoreNameParams,
  UpdateDigitalStoreNameResponse,
} from '@/domain/use-cases/digital-store/update-digital-store-name'
import { UnexpectedError } from '@/domain/errors'

import { BadRequestError, ConflictError } from '@/data/protocols/errors'

export type UpdateDigitalStoreNameHttpsRequestHeaders = {
  'x-api-key': string
}

export type UpdateDigitalStoreNameHttpsRequestBody = {
  digitalStoreName: string
}

export type UpdateDigitalStoreNameHttpsRequestResponseBody = {
  digitalStoreName: string
}

export class RemoteUpdateDigitalStoreName implements UpdateDigitalStoreName {
  constructor(
    private readonly url: string,
    private readonly httpPatchClient: HttpPatchClient<
      UpdateDigitalStoreNameHttpsRequestHeaders,
      UpdateDigitalStoreNameHttpsRequestBody,
      UpdateDigitalStoreNameHttpsRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  updateName = async (params: UpdateDigitalStoreNameParams): Promise<UpdateDigitalStoreNameResponse> => {
    const headers: UpdateDigitalStoreNameHttpsRequestHeaders = {
      'x-api-key': this.xApiKey,
    }

    const body: UpdateDigitalStoreNameHttpsRequestBody = {
      digitalStoreName: params.digitalStoreName,
    }

    const httpResponse = await this.httpPatchClient.patch({
      url: this.url.replace(/:personId/i, params.personId),
      headers,
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.conflict:
        throw new ConflictError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
