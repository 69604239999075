export enum SeguridataRequestsStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}
export enum SeguridataFormStatus {
  APPROVED = 'Approved',
}
export type EventResponse = {
  requestStatus?: SeguridataRequestsStatus
  formStatus?: SeguridataFormStatus
  message?: string
}
