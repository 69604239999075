import { CompanyId } from '@/domain/models/company/company-id'
import { buildTheme } from '@naturacosmeticos/natds-react'
import { themes } from '@naturacosmeticos/natds-web'
import get from 'lodash/get'
import { useEffect, useState } from 'react'
import { ColorScheme } from '@/domain/models/theme-ds/color-scheme'

const BEAUTY_CONSULTANT = 'consultoriaDeBeleza_v2'

const allThemes = {
  [CompanyId.Natura]: themes.natura,
  [CompanyId.Avon]: themes.avon,
}
export const themeMap = (themeWeb, themeReact) => {

  themeWeb.color = { ...themeWeb.color, ...themeReact.color }
  themeWeb.palette.primary.main = themeReact.color.primary
  themeWeb.palette.primary.dark = themeReact.color.primaryDark
  themeWeb.palette.secondary.main = themeReact.color.secondary

  themeWeb.overrides.MuiExpansionPanel.root['&$expanded'].borderColor = themeReact.color.primary

  themeWeb.overrides.MuiInputBase.root['&.Mui-focused'].boxShadow = `${themeReact.color.primary} 0 0 0 2px}`
  
  return themeWeb
}

export const useTheme = (companyId = CompanyId.Natura) => {
  const [theme, setTheme] = useState(themes.natura)


  useEffect(() => {

    if (companyId !== CompanyId.Avon){
      const beautyConsultantTheme = buildTheme(BEAUTY_CONSULTANT, ColorScheme.LIGHT)
      const newThemeMock = themeMap(theme.light, beautyConsultantTheme)
      const newTheme = get(allThemes, companyId, themes.natura)
      newTheme.light = newThemeMock
      setTheme(newTheme)

    } else if (companyId === CompanyId.Avon) {

      const beautyConsultantTheme = buildTheme('avon_v2', ColorScheme.LIGHT)
      const newThemeMock = themeMap(themes.avon_v2.light, beautyConsultantTheme)
      const newTheme = get(allThemes, companyId, themes.avon_v2)
      newTheme.light = newThemeMock
      setTheme(newTheme)

    } else {
      
      const newTheme = get(allThemes, companyId, themes.natura)
      setTheme(newTheme)
      
    }



  }, [companyId, theme.light])

  return theme
}
