import styled from 'styled-components'
import {
  GayaButton as Button,
} from '@naturacosmeticos/natds-react'
import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'
import { deviceSize } from '@/utils/device-sizes'

type ContainerStylePropsType = { hasPreviousButton: boolean, showSkipButton: boolean, showAlternativeFlowButton: boolean, nextButtonTextInline: boolean }

const nextButton = ({ hasPreviousButton }) => (
  !hasPreviousButton
    && {
      marginLeft: 'auto',
      marginRight: 0,
    }
)

const buttonInline = ({ nextButtonTextInline }) => (
  nextButtonTextInline
    && {
      flex: 'none',
    }
)

export const useContainerStyles = makeStyles<Theme, ContainerStylePropsType>((theme) => ({
  actionsLayout: ({ showAlternativeFlowButton }) => ({
    justifyContent: showAlternativeFlowButton ? 'flex-end' : 'space-between',
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    boxSizing: 'border-box',
  }),
  card: ({ showSkipButton }) => ({
    marginBottom: '74px',
    overflow: 'visible',
    boxSizing: 'border-box',
    paddingRight: showSkipButton ? 0 : theme.spacing(2),
    paddingTop: showSkipButton ? 0 : theme.spacing(2),
    padding: theme.spacing(2),

    [theme.breakpoints.down(deviceSize.mobileXL)]: {
      boxShadow: 'none',
      boxSizing: 'border-box',
    },
    [theme.breakpoints.up(deviceSize.mobileXL)]: {
      margin: '0 auto',
      marginTop: theme.spacing(7),
      boxSizing: 'border-box',
      maxWidth: showSkipButton ? theme.spacing(84) : theme.spacing(76),
      padding: `${theme.spacing(3)}px`,
    },
  }),
  cardChildren: ({ showSkipButton }) => ({
    paddingRight: showSkipButton ? theme.spacing(2) : 0,
    paddingTop: showSkipButton ? theme.spacing(7) : 0,

    [theme.breakpoints.up(deviceSize.mobileXL)]: {
      paddingRight: showSkipButton ? theme.spacing(8) : 0,
      paddingTop: showSkipButton ? theme.spacing(5) : 0,
    },
  }),
  nextButton,
  previousButton: ({ showAlternativeFlowButton }) => ({
    marginRight: showAlternativeFlowButton ? 'auto' : '',
  }),
  alternativeFlowButton: {
    float: 'right',
  },
  skipButton: {
    float: 'right',
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  buttonInline,
}))

export const ActionButton = styled(Button)`
  @media (max-width: 460px) {
    width: 100%;
  }

`

export const LoadingContainer = styled.div`
  top: 0;
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
`

export const CenteredLoadingContainer = styled.div`
  margin: 0 auto;
  display: flex;
`
