import React from 'react'
import { Icon } from '@naturacosmeticos/natds-web'
import { UrlCopyButton } from './url-copy-button'

type HeaderProps = {
    text1?: string
    text2?: string
    url: string
    digitalStoreName: string
    buttonText: string
    tooltipText?: string
    disableCopy?: boolean
    toggleFunction: () => void
  }


export const DigitalStoreUrlWithEdit = ({ text1, text2, url, digitalStoreName, toggleFunction, disableCopy, buttonText, tooltipText }: HeaderProps) => {
  const isMobile = window.innerWidth < 720

  const buttonStyle: React.CSSProperties = {
    height: '40px',
    borderRadius: '999px',
    color: '#363636',
    border: '1px solid #F69C00',
    width: '109px',
    background: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    alignSelf: 'flex-end',
  }

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
        <div style={{ display: 'flex', flexDirection:'column', fontSize: '14px' }}>
          {text1 && <span style={{ color: '#111111', fontSize: '16px' }}>{text1}</span> }
          {text2 && <span style={{ color: '#737373' }}>{text2}</span> }
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '10px', flexDirection: isMobile ? 'column' : 'unset'}}>
          <UrlCopyButton disableCopy={disableCopy} url={url+digitalStoreName} tooltipText={tooltipText} />
          <button type='button' style={buttonStyle} onClick={toggleFunction}>
            <div style={{ marginRight: '5px' }}>
              <Icon name='outlined-action-edit' size='micro' color='default' />
            </div>
            {buttonText}
          </button>
        </div>
      </div>
    </>
)
}
