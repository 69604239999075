import React from 'react'

type HeaderProps = {
  isLoading?: boolean
  children: React.ReactNode;
  }

const isMobile = window.innerWidth < 720
const cardStyle = { boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.14)', backgroundColor: '#ffffff', transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms', color: '#363636', padding: isMobile ? '15px' : '40px' }

// Header feito apenas para convergência México
export const StyledCard = ({ isLoading, children }: HeaderProps) => (
  <>
    <div style={isLoading ? { display: 'flex' } : cardStyle}>
      {children}
    </div>
  </>
)
