import React from 'react'

type HeaderProps = {
    title: string
    subheader?: string
  }

// Header feito apenas para convergência México
export const HeaderV2 = ({ title, subheader }: HeaderProps) => (
  <>
    <div style={{ marginBottom: '20px', borderLeft: '4px solid #F69C00', padding: '0 0 0 13px' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 400, color: '#111111' }}>{title}</h1>
      <h2 style={{ fontSize: '14px', fontWeight: 500, color: '#737373', lineHeight: '1.5' }}>{subheader}</h2>
    </div>
  </>
)
