import { RemoteUpdateDigitalStore } from '@/data/use-cases/digital-store'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { UpdateDigitalStore } from '@/domain/use-cases/digital-store'

export const makeUpdateDigitalStore = ():UpdateDigitalStore => {
  const apiUrl = '/digital-store/update-booking/:digitalStoreName'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteUpdateDigitalStore(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
}
