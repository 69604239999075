import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { RemoteGetPendingAgreementByProduct } from '@/data/use-cases/agreement/remote-get-pending-agreement-by-product'
import { GetPendingAgreementByProduct } from '@/domain/use-cases/agreement'

export const makeRemoteGetPendingAgreementByProduct = (): GetPendingAgreementByProduct => {
  const apiUrl = '/people/:personId/pending-agreements'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  return new RemoteGetPendingAgreementByProduct(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
}
