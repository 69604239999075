import { Theme } from '@material-ui/core/styles'
import { makeStyles, TextField } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'

export const useControlledTextFieldStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingTop: '16px',
    paddingBottom: theme.spacing(1.5),
  },
}))

export const FVTextField = styled(TextField)`
  ${({ isELOTheme, theme, state }) => isELOTheme ? 
  `
    .registers-fe-MuiInputLabel-shrink {
      margin-bottom: 5px;
    }
      
    .registers-fe-MuiInputBase-root[data-state='success'] {
      box-shadow: ${theme.color.success} 0 0 0 2px!important;
    }

    .registers-fe-MuiInputBase-root[data-state='error'] {
      box-shadow: ${theme.color.alert} 0 0 0 2px
    }

    .registers-fe-MuiInputBase-root {
      font-size: ${() => theme.textfield?.context?.fontSize ? `${theme.textfield?.context?.fontSize}px` : '16px'};
      font-weight: ${theme.textfield?.fontWeight || '400'};
    }

    .registers-fe-MuiFormLabel-root.Mui-error {
      color: ${theme.color.alert};
    }

    .registers-fe-MuiFormHelperText-root {
      color: ${state === 'success' && theme.color.success} !important;
      font-size: ${() => theme.textfield?.context?.fontSize ? `${theme.textfield?.context?.fontSize}px` : '12px'};
    }

     .registers-fe-MuiInputLabel-shrink {
      color: ${state === 'success' && theme.color.success} !important;
    }

    .registers-fe-MuiFormHelperText-root.Mui-error {
      color: ${theme.color.alert};
    }
      
    .registers-fe-MuiInput-root {
      border-radius: ${theme.textfield?.borderRadius || '16px'};
    }
    .MuiInputBase-root.Mui-disabled {
      color: ${theme.color.lowEmphasis};
      -webkit-text-fill-color: ${theme.color.lowEmphasis};
    }

    .MuiFormLabel-root.Mui-disabled {
      color: ${theme.color.lowEmphasis};
    }
  ` : `
    .MuiInputBase-root.Mui-disabled {
      color: ${theme.color.highEmphasis};
      -webkit-text-fill-color: ${theme.color.highEmphasis};
      background-color: ${theme.color.neutral100};
    }

    .MuiFormLabel-root.Mui-disabled {
      color: ${theme.color.mediumEmphasis};
    }
  `}
  ${({ isMexConvergence }) => isMexConvergence ? `
    .registers-fe-MuiFormHelperText-root.Mui-error {
      position: absolute;
      bottom: -25px;
    }
    .registers-fe-MuiInput-root {
      border-radius: 16px;
    }
  ` : ''}
`
