import { useState } from 'react'
import useSWR from 'swr'
import { makeRemoteCheckPaymentAccount } from '@/main/factories/remote/digital-store/make-remote-check-payment-account'
import { PaymentAccountStatus } from '@/domain/use-cases/digital-store'

const makeCheckPaymentAccountSWR = (countryId, personId, companyId) => [
  `/accounts/registers/v1/payment-service/check-account-exists/${personId}`,
  countryId,
  personId,
  companyId,
]

export function useCheckPaymentAccountSWR({
  countryId,
  personId,
  companyId,
  apiCall = makeRemoteCheckPaymentAccount().load,
}) {
  const [isRevalidating, setIsRevalidating] = useState(false)

  const { data, error, mutate } = useSWR<PaymentAccountStatus>(
    makeCheckPaymentAccountSWR(countryId, personId, companyId),
    async (url, countryId, personId, companyId) => {
      const paymentAccountStatus = await apiCall({
        countryId,
        personId,
        companyId,
      })

      return paymentAccountStatus
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    })


  const reFetch = () => {
    setIsRevalidating(true)
    mutate().then(() => {
      setIsRevalidating(false)
    })
  }

  return {
    paymentAccountStatus: data,
    isLoadingPayment: (!error && !data) || isRevalidating,
    hasError: Boolean(error),
    error,
    reFetch,
    mutate,
  }
}
