import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { DigitalStoreMessages, DigitalStoreStatusDocumentValidationMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { HelpInformation } from '@/main/components/help-information'
import {
  CenteredLoadingContainer,
  LoadingContainer,
} from '@/main/components/pages-structures/container.styles'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Icon, Typography } from '@naturacosmeticos/natds-react'
import { CustomChangeEvent } from '@/main/components/text-field/helper/handle-onchange'
import { DigitalStoreConfigs } from '@/domain/models/tenant/tenant-configs'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Parser } from 'html-to-react'
import { useForm } from 'react-hook-form'
import { StyledCard } from '../../components/styled-card'
import { Content } from '../../digital-store-page.styles'
import { DigitalStoreUrlWithEdit } from './components/digital-store-url-with-edit'
import NameValidation from '../../components/name-validation'
import { useDigitalStore } from '../../hooks/use-digital-store'
import { useTenantConfigs } from '../../../../hooks/useTenantConfigs'
import { IdentityInfoContext } from '../../../../contexts'
import { makeDigitalStorePageApi } from '../../api/make-digital-store-page-api'
import SaveErrorDialog from '../../components/save-error-dialog'


type Debounce = {
  delay: number
  countdown: any
}

const panelButtonStyle: React.CSSProperties = {
  height: '48px',
  fontWeight: 500,
  borderRadius: '999px',
  color: '#333333',
  border: 'none',
  background: '#FAC871',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  padding: '24px',
  margin: '32px 0',
}

const saveButtonStyle: React.CSSProperties = {
  ...panelButtonStyle,
  color: 'revert',
  border: '1px solid #FAC871',
  background: 'transparent',
  margin: '0 16px',
  gap: '10px',
  minWidth: '131px'
}

export const DigitalStoreActive = ({
  isLoading: pageLoading, digitalStoreStatus, convergence = true
}) => {

  const [digitalStoreDefault, setDigitalStoreDefault] = useState(digitalStoreStatus?.digitalStoreName !== null)
  const [editActive, toggleEdit] = useState(false)
  const [errorOnSaveName, setErrorOnSaveName] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)

  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status

  const pageMessages = usePageMessages(Page.DigitalStoreStatusDocumentValidation).messages as DigitalStoreStatusDocumentValidationMessages
  const digitalStoreMessages = usePageMessages(Page.DigitalStore).messages as DigitalStoreMessages
  const statusMessages = pageMessages.statusMessages[status]

  const messages = {
    ...digitalStoreMessages,
    ...statusMessages,
    ...pageMessages
  }
  const htmlToReactParser = new Parser()
  const isMobile = window.innerWidth < 720

  const formMethods = useForm({
    mode: 'onTouched',
  })

  const {
    formState,
  } = formMethods

  const {
    isLoading,
    helperTextKey,
    isAvailable,
    setHelperTextKey,
    checkAvailability,
    currentDigitalStoreName,
    setHasError,
    hasError,
    shouldDisableInput,
    setCurrentDigitalStoreName,
    shouldDisableVerifyButton,
    updateName,
  } = useDigitalStore({
    api: makeDigitalStorePageApi(),
    convergence,
    extraEntriesForm: formState,
    digitalStoreDefault
  })

  const {
    tenantId,
  } = useContext(IdentityInfoContext)

  const {
    digitalStoreNameMaxOfChars,
    showWarningAfterGetSubmitError
  } = useTenantConfigs(tenantId, Page.DigitalStore) as DigitalStoreConfigs

  const delay = (size: number) => new Promise((resolve) => {
    setTimeout(() => resolve(null), size)
  })

  const handleCheckAvailability = () => {
    checkAvailability(currentDigitalStoreName)
  }

  const debounce = useMemo<Debounce>(() => ({
    delay: 200,
    countdown: 0,
  }), [])

  const handleOnChangeInput = (event: CustomChangeEvent) => {
    const { value } = event.target
    setDigitalStoreDefault(false)

    const hasValidNameCharacters = (name: string) => (
      name.match(/^[a-z0-9_]+$/i) !== null
    )

    const hasPassedMaxNumOfChars = (name: string, maxNum:number) => {
      let hassPassed = false
      if (maxNum) {
        hassPassed = name.length > maxNum
      }
      return hassPassed
    }

    const onDoneHandleChangeInput = (error: boolean, helperTextKey: string) => {
      setHasError(error)
      setHelperTextKey(helperTextKey)
    }

    const resetDigitalStoreStates = async () => {
      await delay(100)
      onDoneHandleChangeInput(false, '')
    }

    setCurrentDigitalStoreName(value)

    clearTimeout(debounce.countdown)
    debounce.countdown = setTimeout(() => {
      if (hasValidNameCharacters(value) && !hasPassedMaxNumOfChars(value, digitalStoreNameMaxOfChars)) {
        resetDigitalStoreStates()
      } else {
        onDoneHandleChangeInput(true, 'invalidNameSpace')
      }
    }, debounce.delay)
  }

  const handleUpdateDigitalStore = async () => {
    try {
      setLoadingUpdate(true)
      await updateName(currentDigitalStoreName)
      setEdit()
      setLoadingUpdate(false)
    } catch (error) {
      setHasError(true)
      setHelperTextKey('errorSaving')
      setLoadingUpdate(false)
      setErrorOnSaveName(true)
    }
  }

  const checkDigitalStoreNameState = () => {
    if (hasError) {return 'error'}
    if (isAvailable) {return 'success'}
    if (digitalStoreDefault) {return 'success'}
    return undefined
  }

  const setEdit = () => {
    toggleEdit(!editActive)
  }

  const getErrorMessages = useCallback((): string | undefined => {
    const errorIndex: string[] = ['unavailableName', 'invalidNameSpace', 'genericError', 'errorSaving']
    if (!!helperTextKey && errorIndex.includes(helperTextKey)) {
      return digitalStoreMessages[helperTextKey]
    }
    return undefined
  }, [helperTextKey, digitalStoreMessages])

  const openCTAUrl = () => {
    window.open(messages.buttons.digitalPanelLink, '_self')
  }

  useEffect(() => {
    if (digitalStoreStatus?.digitalStoreName) {
      setCurrentDigitalStoreName(digitalStoreStatus?.digitalStoreName)
    }

  }, [digitalStoreStatus?.digitalStoreName])


  if (pageLoading) {
    return (
      <LoadingContainer data-testid="loading-container">
        <CenteredLoadingContainer>
          <ProgressIndicator />
        </CenteredLoadingContainer>
      </LoadingContainer>
    )
  }

  return (
    <div style={{ margin: isMobile ? '20px 20px 80px' : '50px auto', maxWidth: '1080px' }}>
      <StyledCard>
        <Content style={{ maxWidth: '570px', marginBottom: '16px' }}>
          <SaveErrorDialog
            open={errorOnSaveName}
            message={messages.dialogError.message}
            onClickOk={() => setErrorOnSaveName(false)}
            okButtonLabel={messages.dialogError.buttonOk}
            shouldRender={showWarningAfterGetSubmitError}
          />
          <div style={{ display: 'flex', gap: '15px', marginBottom: '16px' }}>
            <Icon
              color='success'
              size='semiX'
              name="filled-alert-check"
            />
            <div>
              <Typography variant="heading4">
                {messages.title}
              </Typography>
              <Typography variant="subtitle1">
                {messages.subtitle}
              </Typography>
            </div>
          </div>

          <Typography variant="body1">
            {messages.description}
          </Typography>
        </Content>

        <div style={{ minWidth: isMobile ? '250px' : '500px'}}>
          {!editActive && (
            <div style={{ display: 'flex', flexDirection: 'column', gap: isMobile && '10px' }}>
              <Typography variant="body2">
                {htmlToReactParser.parse(messages.links.linkDescription)}
              </Typography>
              <DigitalStoreUrlWithEdit
                toggleFunction={setEdit}
                tooltipText={messages.editDigitalStoretooltipText}
                buttonText={messages.buttons.edit}
                url={messages.links.url}
                digitalStoreName={currentDigitalStoreName}
              />
            </div>
            )}

          {editActive && (
            <div style={{ maxWidth: '630px', marginBottom: '36px' }}>
              <NameValidation
                label={messages.nameLabel}
                buttonLabel={messages.verifyButtonLabel}
                buttonIsLoading={isLoading && !loadingUpdate}
                errorMessage={getErrorMessages()}
                buttonOnClick={() => handleCheckAvailability()}
                buttonOnChange={() => setHelperTextKey('')}
                extraButton={(
                  <div style={{ borderLeft: '1px solid #A4A4A4', height: '50px', marginLeft: '16px' }}>
                    <SaveButton
                      type='button'
                      data-testid='save-name'
                      isLoading={loadingUpdate}
                      disabled={!isAvailable}
                      onClick={handleUpdateDigitalStore}
                    >
                      {messages.buttons.save}
                    </SaveButton>
                  </div>
                )}
                onChangeInput={handleOnChangeInput}
                isMexConvergence
                shouldButtonBeDisabled={shouldDisableVerifyButton}
                disabled={shouldDisableInput}
                isSuccess={checkDigitalStoreNameState() === 'success'}
                invalid={checkDigitalStoreNameState() === 'error'}
                successMessage={checkDigitalStoreNameState() === 'success' ? messages.availableName : undefined}
                inputValue={currentDigitalStoreName}
              />
            </div>
          )}

          <button
            type='button'
            style={panelButtonStyle}
            onClick={openCTAUrl}
          >
            {messages.buttons.digitalPanel}
          </button>
        </div>

        <div style={{ borderTop: '1px solid #A4A4A4', marginTop: isMobile && '15px', marginBottom: '15px' }} />

        <HelpInformation
          title={messages.contactInfoHelpTitle}
          content={messages.contactInfoHelp}
        />
      </StyledCard>
    </div>
  )
}

const SaveButton = ({ isLoading, children, ...props }) => {
  return (
    <button type='button' style={saveButtonStyle} {...props}>
      {isLoading ? (
        <ProgressIndicator data-testid="loading-verify" size={24} />
      ) : (
        <>
          <Icon size='small' name="outlined-action-save" />
          {children}
        </>
      )}
    </button>
  )
}
