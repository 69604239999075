import { dataLayerPush } from './helpers/dataLayerPush'

export const registerFormPushes = (formInitialValues, getValues, messages, emailMessages) => {
  const { email, primary, secondary } = getValues()
  if (formInitialValues.current.phoneNumber && formInitialValues.current.phoneNumber !== 'PUSHED' && primary?.phoneNumber !== formInitialValues.current.phoneNumber) {
    dataLayerPush('form_step', { option_selected: 'contact data', step_name: messages.phoneNumber.label })
    formInitialValues.current.phoneNumber = 'PUSHED'
  }
  if (formInitialValues.current.phoneNumberSecondary && secondary?.phoneNumber && formInitialValues.current.phoneNumberSecondary !== 'PUSHED' && secondary?.phoneNumber !== formInitialValues.current.phoneNumberSecondary) {
    dataLayerPush('form_step', { option_selected: 'contact data', step_name: messages.phoneNumber.secondaryPhoneLabel })
    formInitialValues.current.phoneNumberSecondary = 'PUSHED'
  }
  if (formInitialValues.current.email && formInitialValues.current.email !== 'PUSHED' && email !== formInitialValues.current.email) {
    dataLayerPush('form_step', { option_selected: 'contact data', step_name: emailMessages.label })
    formInitialValues.current.email = 'PUSHED'
  }
  if (formInitialValues.current.primaryPhoneType && formInitialValues.current.primaryPhoneType !== 'PUSHED' && primary?.type !== formInitialValues.current.primaryPhoneType) {
    dataLayerPush('form_step', { option_selected: 'contact data', step_name: messages.phoneTypes?.label })
    formInitialValues.current.primaryPhoneType = 'PUSHED'
  }
  if (formInitialValues.current.secondaryPhoneType && secondary?.type && formInitialValues.current.secondaryPhoneType !== 'PUSHED' && secondary?.type !== formInitialValues.current.secondaryPhoneType) {
    dataLayerPush('form_step', { option_selected: 'contact data', step_name: messages.phoneTypes?.label })
    formInitialValues.current.secondaryPhoneType = 'PUSHED'
  }
  return formInitialValues.current
}

export const personalDataFormPushes = (formInitialValues, getValues, nicknameMessages, genderMessages, racialSelfDeclarationMessages) => {

  const { nickname, gender, racialSelfDeclaration } = getValues()
  if (formInitialValues.current.nickname !== 'PUSHED' && nickname !== formInitialValues.current.nickname) {
    dataLayerPush('form_step', { option_selected: 'personal data', step_name: nicknameMessages.label })
    formInitialValues.current.nickname = 'PUSHED'
  }
  if (formInitialValues.current.genderLabel !== 'PUSHED' && gender?.label !== formInitialValues.current.genderLabel) {
    dataLayerPush('form_step', { option_selected: 'personal data', step_name: genderMessages.label })
    formInitialValues.current.genderLabel = 'PUSHED'
  }
  if (formInitialValues.current.racialSelfDeclarationLabel !== 'PUSHED' && racialSelfDeclaration?.label !== formInitialValues.current.racialSelfDeclarationLabel) {
    dataLayerPush('form_step', { option_selected: 'personal data', step_name: racialSelfDeclarationMessages.label })
    formInitialValues.current.racialSelfDeclarationLabel = 'PUSHED'
  }


  return formInitialValues.current
}