import React from 'react'

import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'

import { CountryId } from '@/domain/models/country/country-id'
import { CompanyId } from '@/domain/models/company/company-id'
import { DigitalStoreStatusNotFound } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-not-found/digital-store-status-not-found'
import { DigitalStorePage } from '../../../../digital-store-page'
import { DigitalStorePageV2 } from '../../../../digital-store-page-v2'
import { DigitalStoreStatusDefaultContent } from '../../digital-store-status-default-content'
import { DigitalStoreStatusPending } from '../../digital-store-status-pending'
import { DigitalStoreStatusPendingAgreements } from '../../digital-store-status-pending-agreements'
import { DigitalStoreStatusPayment } from '../../digital-store-status-payment'
import { makeDigitalStorePageApi } from '../../../../api/make-digital-store-page-api'
import { DigitalStoreStatusMercadoPago } from '../../components/digital-store-status/digital-store-status-mercado-pago/digital-store-status-mercado-pago'
import { DigitalStorePendingDocumentValidation } from '../../digital-store-status-pending-document-validation'
import { DigitalStoreUnableToSell } from '../../digital-store-status-unable-to-sell'
import { DigitalStoreActive } from '../../digital-store-status-active'


const validateRequiredDocument = (prioritizeDigitalStoreScreen, documentTypeRequired, documents): boolean => {
  if (prioritizeDigitalStoreScreen && documentTypeRequired){
    return documents.some((document) => document.type === documentTypeRequired)
  }
  return false
}

const getSelfHelpContentIfAllowed = () => {
  const { ENABLE_DIGITAL_STORE_WITH_MERCADO_PAGO_PERU } = process.env
  const isAllowedSelfHelpContent = ENABLE_DIGITAL_STORE_WITH_MERCADO_PAGO_PERU

  return isAllowedSelfHelpContent ? DigitalStoreStatusPayment : DigitalStoreStatusDefaultContent
}
  const defaultStatusComponents = {
    [DigitalStoreCurrentStatus.PENDING_AGREEMENTS]: DigitalStoreStatusPendingAgreements,
    __default__: DigitalStoreStatusDefaultContent,
  }

  const peruStatusComponents = {
    ...defaultStatusComponents,
    [DigitalStoreCurrentStatus.ACTIVE]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.PENDING_BANK_ACCOUNT]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.PENDING_PAYMENT_ACCOUNT]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.SUSPENDED]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.NOT_STARTED]: ({ onSave }) => <DigitalStorePage convergence onSave={onSave} api={makeDigitalStorePageApi()} />,
  }

  const getForPanelCND = () => window.location.replace(process.env.PANEL_CND)

  const mexicoStatusComponents = {
    ...defaultStatusComponents,
    [DigitalStoreCurrentStatus.ACTIVE]: process.env.ENABLE_CND_PANEL_REDIRECT_DS_STATUS_ACTIVE === 'true' ? getForPanelCND : getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.NOT_STARTED]: ({ onSave }) => <DigitalStorePage convergence onSave={onSave} api={makeDigitalStorePageApi()} />,
    [DigitalStoreCurrentStatus.SUSPENDED]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.CEASED]: getSelfHelpContentIfAllowed(),
  }

  const naturaMexicoStatusComponents = {
    ...defaultStatusComponents,
    [DigitalStoreCurrentStatus.ACTIVE]: DigitalStoreActive,
    [DigitalStoreCurrentStatus.UNABLE_TO_SELL]: DigitalStoreUnableToSell,
    [DigitalStoreCurrentStatus.PENDING_PAYMENT_ACCOUNT]: DigitalStoreStatusMercadoPago,
    [DigitalStoreCurrentStatus.PENDING]: DigitalStoreStatusPending,
    [DigitalStoreCurrentStatus.PENDING_DOCUMENT_VALIDATION]: DigitalStorePendingDocumentValidation,
    [DigitalStoreCurrentStatus.SUSPENDED]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.PENDING_AGREEMENTS]: ({ onSave }) => <DigitalStorePageV2 convergence onSave={onSave} api={makeDigitalStorePageApi()} />,

    [DigitalStoreCurrentStatus.NOT_STARTED]: ({ onSave }) => <DigitalStorePageV2 isNotStartedStatus convergence onSave={onSave} api={makeDigitalStorePageApi()} />,
  }

  const NotMappedCountryComponents = {
    __default__: DigitalStoreStatusNotFound,
  }

  const statusComponentPerCountry = {
    [`${CountryId.Peru}-${CompanyId.Natura}`]: peruStatusComponents,
    [`${CountryId.Malaysia}-${CompanyId.Natura}`]: defaultStatusComponents,
    [`${CountryId.Mexico}-${CompanyId.Avon}`]: mexicoStatusComponents,
    [`${CountryId.Mexico}-${CompanyId.Natura}`]: naturaMexicoStatusComponents,
    __default__: NotMappedCountryComponents,
  }

  export const getStoreStatusPageByStatus = ({
    digitalStoreStatus,
    countryId,
    shouldRenderDigitalStorePage = false,
    companyId,
    digitalStoreName = null,
    shouldPrioritizeDigitalStoreScreen = false,
    documentTypeRequiredInDigitalStoreScreen = null,
    personDocuments = []
  }) => {
    if (shouldRenderDigitalStorePage) {
      return ({ onSave }) => (
        <DigitalStorePage
          convergence
          onSave={onSave}
          api={makeDigitalStorePageApi()}
        />
      )
    }

    const statusComponents = statusComponentPerCountry[`${countryId}-${companyId}`] || statusComponentPerCountry.__default__

    const Component = statusComponents[digitalStoreStatus] || statusComponents.__default__


    const hasMissingDocuments = validateRequiredDocument(
      shouldPrioritizeDigitalStoreScreen,
      documentTypeRequiredInDigitalStoreScreen,
      personDocuments
    )

    const hasPriorizedigitalStorePage = digitalStoreStatus !== DigitalStoreCurrentStatus.NOT_STARTED && !hasMissingDocuments

    //  If apenas para Natura Mexico, está sendo desenvolvido antes de termos os componentes novos do DS
    if (shouldPrioritizeDigitalStoreScreen && (digitalStoreStatus === DigitalStoreCurrentStatus.PENDING_AGREEMENTS || hasPriorizedigitalStorePage)) {
      return ({ onSave, refetchPersonData }) => (
        <DigitalStorePageV2
          convergence
          onSave={onSave}
          api={makeDigitalStorePageApi()}
          digitalStoreName={digitalStoreName}
          refetchPersonData={refetchPersonData}
        />
      )
    }

    return Component
  }
