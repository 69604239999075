import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { NotFoundError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { GenerateCredentials, GenerateCredentialsParams } from '@/domain/use-cases/document-validation'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'

export type GenerateCredentialsHttpRequestHeaders = {
  'x-api-key': string
  countryid: CountryId
  companyid: CompanyId
}

export type GenerateCredentialsHttpResponseBody = {
  status: number
  data: {
    authentication: {
      tokenId: string
      requestId: string
      productId: string
    }
    weblink: string
  }
}

export class RemoteGenerateCredentials implements GenerateCredentials {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
    GenerateCredentialsHttpRequestHeaders,
    GenerateCredentialsHttpResponseBody
    >,
    private readonly xApiKey: string,
  ) {}

  get = async (params: GenerateCredentialsParams): Promise<GenerateCredentialsHttpResponseBody> => {
    const headers: GenerateCredentialsHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      countryid: params.countryId,
      companyid: params.companyId,
    }

    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(/:personId/i, params.personId),
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
