import React, { useContext } from 'react'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusDefaultContentMessages } from '@/domain/models/messages/messages'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { IdentityInfoContext } from '@/main/contexts'
import { StatusIcon } from '@/main/components'
import { Card } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/Card/card'
import { Link, Typography } from '@naturacosmeticos/natds-web'
import { Parser } from 'html-to-react'
import { Container } from './components/digital-store-status/digital-store-status-base-card.styles'
import { getProfileUrl } from '../../../../../utils/create-profile-url'

const buttonStyle: React.CSSProperties = {
  height: '48px',
  fontWeight: 500,
  borderRadius: '999px',
  color: '#333333',
  border: 'none',
  background: '#FAC871',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '14px',
  margin: '32px 0',
  width: '154px',
}

export const DigitalStoreStatusPending = ({
  digitalStoreStatus,
}) => {
  const htmlToReactParser = new Parser()
  const { countryId } = useContext(IdentityInfoContext)
  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status
  const messages = usePageMessages(Page.DigitalStoreStatusDefaultContent).messages as DigitalStoreStatusDefaultContentMessages
  const statusMessages = messages.statusMessages[status]

  const handleProfileRedirect = () => {
    const profileUrl = getProfileUrl(countryId)
    window.location.assign(profileUrl)
  }

  return (
    <Container style={{ flexDirection: 'column', alignItems: 'center' }}>
      <Card>
        <StatusIcon name={statusMessages?.type} size='64px' data-testId="statusIcon" />
        <Typography variant='h6'>
          {statusMessages?.title}
        </Typography>
        <Typography variant='body1'>
          {statusMessages?.subtitle}
        </Typography>

        <Typography variant='subtitle1' style={{ margin: '32px 0 8px' }}>
          {statusMessages?.contactInfoHelp.title}
        </Typography>

        <Typography variant='body2'>
          {htmlToReactParser.parse(statusMessages?.contactInfoHelp.text)}
          <Link target='_blank' href={statusMessages?.contactInfoHelp.link}>
            {htmlToReactParser.parse(statusMessages?.contactInfoHelp.linkDescription)}
          </Link>
        </Typography>
      </Card>

      <button
        type='button'
        style={buttonStyle}
        onClick={handleProfileRedirect}
      >
        {statusMessages?.buttons.profile}
      </button>
    </Container>
  )
}
