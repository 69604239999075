import { call, put, delay } from 'redux-saga/effects'
import { UpdateDigitalStoreParams } from '@/domain/use-cases/digital-store'
import { makeUpdateDigitalStore } from '@/main/factories/remote/digital-store'
import { BadRequestError, ConflictError } from '@/data/protocols/errors'
import { makeRemoteUpdatePersonSubstatus } from '@/main/factories/remote/person'
import { UpdatePersonSubstatusParams } from '@/domain/use-cases/person'
import { PERSON_SUBSTATUS_ID } from '@/domain/models/person'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { updateDigitalStoreSuccess, updateDigitalStoreError } from '../actions'

const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()
export const DELAY_TO_SUCCESS_UPDATE = 5000

export function* updateDigitalStore(action) {
  const {
    digitalStoreName,
    personId,
    country,
    functionId,
    role,
    businessModel,
    sourceSystem,
    convergence,
    agreementId,
    accepted,
    companyId,
    extraEntries,
  } = action.payload

  const countryId = country.length === 2 ? convertCountryIdFromAlpha2ToAlpha3Presenter.handle({ countryId: country }) : country

  try {
    const { update } = makeUpdateDigitalStore()
    const params: UpdateDigitalStoreParams = {
      countryId,
      companyId,
      personId,
      digitalStoreName,
      agreementId,
      accepted,
      extraEntries,
    }
    const remoteUpdatePersonSubstatus = makeRemoteUpdatePersonSubstatus()
    const updatePersonSubstatusParams: UpdatePersonSubstatusParams = {
      personId,
      substatusId: PERSON_SUBSTATUS_ID.FINAL_VALIDATION,
      functionId,
      role,
      businessModel,
      sourceSystem,
      countryId,
      companyId,
    }

    const saved = yield call(update, params)

    if (convergence) {
      yield delay(DELAY_TO_SUCCESS_UPDATE)
    } else yield call(remoteUpdatePersonSubstatus.update, updatePersonSubstatusParams)

    const helperTextKey = saved
      ? 'availableName'
      : 'errorSaving'
    const error = !saved
    yield put(updateDigitalStoreSuccess(saved, helperTextKey, error))
  } catch (errorResponse) {
    let helperTextKey: string
    switch (errorResponse.constructor) {
      case ConflictError:
      case BadRequestError:
        helperTextKey = 'errorSaving'
        break
      default:
        helperTextKey = 'genericError'
    }
    yield put(updateDigitalStoreError(helperTextKey))
  }
}
