import { RemoteCheckPaymentAccount } from '@/data/use-cases/digital-store'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeRemoteCheckPaymentAccount = (): RemoteCheckPaymentAccount => {
  const apiUrl = '/payment-service/check-account-exists'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteCheckPaymentAccount = new RemoteCheckPaymentAccount(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteCheckPaymentAccount
}
