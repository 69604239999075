import { ADDRESS_STORAGE_NAME, CHANNEL_STORAGE_NAME } from '@/domain/models/address'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { AddressConfigs, ProfileConfigs } from '@/domain/models/tenant/tenant-configs'
import { countryCompanyToTenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { IdentityInfoContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { removeItemInStorage } from '@/main/hooks/useStorage'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader, Icon, Typography } from '@naturacosmeticos/natds-react'
import React, { useContext, useEffect, useState } from 'react'
import { capitalizeWords } from '@/utils/capitalize-words'
import { dataLayerPush } from '@/analytics/helpers/dataLayerPush'
import { CustomCard } from '../components/custom-card'
import { useAddressStyles } from './address.styles'

export type AddressType = {
  zipCode: string
  addressJoin: string
  reference: string
}

const SOURCESYSTEM_MINEGOCIO = '50'

export type AddressProps = {
  addressData: any
  showEditButton?: boolean
  showModalEditAddress?: boolean
}

export const Address = ({ addressData, showEditButton, showModalEditAddress = false }: AddressProps) => {
  const [address, setAddress] = useState<AddressType>()
  const componentClasses = useAddressStyles()
  const { personId, companyId, sourceSystem, countryId } = useContext(IdentityInfoContext)
  const [statusModal, setStatusModal] = useState(false)

  const tenantId = countryCompanyToTenantIdMapper[companyId][countryId]

  const {
    address: { getAddress, getReference, editUrl, minegocioEditUrl },
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const { zipCode: zipCodeConfig } = useTenantConfigs(tenantId, Page.Address) as AddressConfigs

  const { addresses: messages, editButton: editButtonName } = usePageMessages(Page.MyProfile)
    .messages as MyProfileMessages

  let editButtonLink = ''
  const showEditButtonByCountry =
    process.env.ENABLE_UPDATE_ADDRESS_BY_COUNTRY &&
    process.env.ENABLE_UPDATE_ADDRESS_BY_COUNTRY.includes(countryId.toLocaleUpperCase())
  if (showEditButtonByCountry) {
    if (sourceSystem && sourceSystem === SOURCESYSTEM_MINEGOCIO) {
      editButtonLink = minegocioEditUrl
    } else {
      editButtonLink = `${editUrl}${personId}`
    }
  }

  useEffect(() => {
    setAddress({
      zipCode:
        addressData.zipCode && addressData.zipCode.replace(zipCodeConfig.regex, zipCodeConfig.regexOption),
      addressJoin: getAddress(addressData),
      reference: addressData.references || getReference && getReference(addressData) || messages.withoutInformation,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const openModal = () => setStatusModal(true)
  const closeModal = () => setStatusModal(false)

  return (
    <CustomCard
      title={messages.title}
      className={componentClasses.container}
      editButtonName={showEditButton && editButtonName}
      editButtonLink={showEditButton && editButtonLink}
      onClickCapture={() => {
        dataLayerPush('edit_my_profile', { option_selected: 'address' })
        if (showModalEditAddress) openModal()
        removeItemInStorage(ADDRESS_STORAGE_NAME)
        removeItemInStorage(CHANNEL_STORAGE_NAME)
      }}
      disableClick={showModalEditAddress}
    >
      <div className={componentClasses.addressContainer} data-testid="address">
        {!!messages?.zipCode && (
          <>
            <Typography
              variant="subtitle2"
              className={componentClasses.addressSubtitleSpace}
            >
              {messages?.zipCode}
            </Typography>
            <Typography variant="body1" className={componentClasses.addressInformation}>{address?.zipCode}</Typography>
          </>
        )}
        <Typography variant="subtitle2" className={componentClasses.addressSubtitleSpace}>
          {messages.addressJoin}
        </Typography>
        <Typography variant="body1" className={componentClasses.addressInformation}>{capitalizeWords(address?.addressJoin) ?? ''}</Typography>
        <Typography variant="subtitle2" className={componentClasses.addressSubtitleSpace}>
          {messages.reference}
        </Typography>
        <Typography variant="body1" className={componentClasses.addressInformation}>{capitalizeWords(address?.reference)}</Typography>
      </div>
      <Dialog
        ariaDescribedBy="dialogStatusDescription"
        ariaLabelledBy="dialogStatusTitle"
        onEscapeKeyDown={closeModal}
        open={statusModal}
        role="alertdialog"
        size="medium"
      >
        <DialogHeader
          id="dialogStatustitleHeader"
          title={messages.modalTitle}
          className={componentClasses.modalHeader}
        >
          <Icon color="highlight" name="outlined-alert-warning" size="small" />
        </DialogHeader>
        <DialogBody>
          <p id="dialogStatusDescriptionBody">{messages.modalMessage}</p>
        </DialogBody>
        <DialogFooter primaryButton={<Button onClick={closeModal}>{messages.modalNameButton}</Button>} />
      </Dialog>
    </CustomCard>
  )
}
